











































































































































import User from "../api/User.api";
import { shouldViewAsSuperUser } from "@/utils";
import config from "@/config";

export const superUserCreds = (): any | boolean => {
  let superUserCreds = localStorage.getItem("superUserCreds");
  if (superUserCreds) {
    try {
      superUserCreds = JSON.parse(superUserCreds);
      return superUserCreds;
    } catch (e) {
      return false;
    }
  }
  return false;
};
export const isViewingAsOtherUser = () => {
  const creds = superUserCreds();
  return !!(
    creds &&
    creds.token &&
    creds.token !== localStorage.getItem("JWT")
  );
};

export default {
  name: "Navbar",
  data() {
    return {
      internalShowChangeUserViewDialog: false,
      users: [],
      usersListLabel: "loading users",
      showRevisionInfo: true,
      viewAs: "",
      showNews: !(
        window.localStorage.getItem("dismissed-browse-next-message") === "true"
      ),
      selectedUser: {
        name: "",
        email: "",
        id: "",
      },
      config,
    };
  },
  computed: {
    browseNextUrl() {
      if (window.location.host.includes("test")) {
        return "https://browse-next-test.cacophony.org.nz";
      }
      return "https://browse-next.cacophony.org.nz";
    },
    revisionInfo() {
      let version = this.config.revisionInfo.version;
      if (this.config.revisionInfo.travis) {
        version =
          this.config.revisionInfo.travis.branch ||
          this.config.revisionInfo.travis.tag;
      }
      const commitTime = new Date(Date.parse(this.config.revisionInfo.time));
      return `${
        this.config.revisionInfo.branch
      } :: ${version}, ${commitTime.toLocaleDateString()} ${commitTime.toLocaleTimeString()}`;
    },
    revisionLink() {
      const info = this.config.revisionInfo;
      let slug;
      if (info.travis && info.travis.tag) {
        slug = `releases/tag/${info.travis.tag}`;
      } else {
        slug = `commit/${info.commit}`;
      }
      return `https://github.com/TheCacophonyProject/cacophony-web/${slug}`;
    },
    commitMessage() {
      if (this.config.revisionInfo.travis) {
        return this.config.revisionInfo.travis.commitMessage;
      }
      return false;
    },
    userName() {
      return this.$store.state.User.userData.userName;
    },
    globalPermission() {
      return this.$store.state.User.userData.globalPermission;
    },
    isSuperUser() {
      return this.globalPermission === "write";
    },
    showChangeUserViewDialog: {
      async set(val) {
        this.internalShowChangeUserViewDialog = val;
        if (this.users.length === 0) {
          await this.initUsersList();
          this.usersListLabel = "select a user";
        }
      },
      get() {
        return this.internalShowChangeUserViewDialog;
      },
    },
    isViewingAsSuperUser() {
      return this.viewAs === "super";
    },
    isViewingAsRegularUser() {
      return this.viewAs === "regular";
    },
  },
  mounted() {
    this.viewAs = shouldViewAsSuperUser() ? "super" : "regular";

    const h = this.$createElement;
    // Create a ID with a incremented count
    const id = `my-toast-${this.count++}`;

    // Create the custom close button
    const $closeButton = h(
      "div",
      {
        on: { click: () => this.$bvToast.hide(id) },
      },
      "Close"
    );
    this.$bvToast.show("browse-next-toast");
    //
    // this.$bvToast.toast(``, {
    //   title: 'News',
    //   variant: "warning",
    //   appendToast: false,
    //   solid: true
    // })
  },
  methods: {
    dismissMessage() {
      window.localStorage.setItem("dismissed-browse-next-message", "true");
    },
    async initUsersList() {
      if (this.isSuperUser) {
        const response = await User.list();
        if (response.success) {
          this.users = response.result.usersList
            .map(({ userName, id, email }) => ({
              name: `${userName} ${email ? `<${email}>` : ""}`,
              email,
              id,
            }))
            .filter(({ email }) => email !== this.superUserEmail());
        }
      }
    },
    superUserName() {
      const creds = superUserCreds();
      return creds && creds.userName;
    },
    superUserEmail() {
      const creds = superUserCreds();
      return creds && creds.email;
    },
    logout() {
      this.$store.dispatch("User/LOGOUT");
      this.$router.go("home");
    },
    async changeViewingUser() {
      if (this.selectedUser) {
        // Log in as user:
        const otherUser = await User.loginOther(
          this.selectedUser.email || this.selectedUser.id
        );
        this.$store.dispatch("User/LOGIN_OTHER", otherUser.result);
        window.location.reload();
      }
    },
    revertViewingUser() {
      const superUser = superUserCreds();
      if (superUser) {
        this.$store.dispatch("User/LOGIN_OTHER", {
          userData: { ...superUser },
          token: superUser.token,
        });
        this.selectedUser = null;
        window.location.reload();
      }
    },
    viewAsSuperUser() {
      localStorage.setItem("view-as", "super");
      this.viewAs = "super";
      // TODO: Would be nicer to not require a reload here, but maybe doesn't matter since this is really just an
      //  admin/debug feature
      window.location.reload();
    },
    viewAsRegularUser() {
      localStorage.setItem("view-as", "regular");
      this.viewAs = "regular";
      // TODO: Would be nicer to not require a reload here, but maybe doesn't matter since this is really just an
      //  admin/debug feature
      window.location.reload();
    },
    superUserCreds() {
      return superUserCreds();
    },
    isViewingAsOtherUser() {
      return isViewingAsOtherUser();
    },
  },
};
