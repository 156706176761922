














































































import api from "@api";
import { AlertId } from "@typedefs/api/common";
import ClassificationsDropdown from "@/components/ClassificationsDropdown.vue";

export default {
  name: "StationAlertsTab",
  components: { ClassificationsDropdown },
  props: {
    station: {
      required: true,
    },
    group: {
      required: true,
    },
  },
  data() {
    return {
      alerts: [],
      creatingAlert: false,
      currentAlertTag: "",
    };
  },
  computed: {
    alertToDelete: {
      get() {
        return this.alerts.length !== 0 && !!this.alertToDeleteId;
      },
      set(val) {
        if (typeof val === "boolean" && val === false) {
          this.unsetDeleteAlert();
        }
      },
    },
    alertToDeleteId() {
      return this.$route.params.deleteItemId;
    },
    alertToDeleteItem() {
      return this.alerts.find(
        ({ id }) => id === parseInt(this.alertToDeleteId)
      );
    },
    alertToDeleteName() {
      return this.alertToDeleteItem?.name;
    },
    alertToDeleteTag() {
      return this.alertToDeleteItem?.conditions[0].tag;
    },
    tableAlerts() {
      return this.alerts.map(({ id, name, conditions, lastAlert }) => {
        return {
          id,
          name,
          alertsOn: conditions.map(({ tag }) => tag).join(", "),
          lastAlert:
            lastAlert === "never"
              ? lastAlert
              : new Date(lastAlert).toLocaleString(),
          remove: "",
        };
      });
    },
  },
  methods: {
    unsetDeleteAlert() {
      const params = this.$route.params;
      delete params.deleteItemId;
      this.$router.replace({
        name: this.$route.name,
        params,
      });
    },
    setDeleteAlert(id: AlertId) {
      this.$router.replace({
        name: this.$route.name,
        params: {
          ...this.$route.params,
          deleteItemId: id,
        },
      });
      //alertToDeleteId = data.item.id
    },
    async removeAlert(id: AlertId) {
      const response = await api.alerts.removeAlert(id);
      if (response.success) {
        this.$emit("alerts-changed");
        await this.fetchAlerts();
      }
    },
    createAlert(e) {
      this.currentAlertTag = "";
      this.creatingAlert = true;
    },
    async submitAlert() {
      const response = await api.alerts.createAlertForStation(
        this.station.id,
        this.currentAlertTag
      );
      if (response.success) {
        this.$emit("alerts-changed");
        await this.fetchAlerts();
      }
    },
    async fetchAlerts() {
      const alerts = await api.alerts.getAlertsForStation(this.station.id);
      if (alerts.success) {
        this.alerts = alerts.result.alerts;
      }
    },
  },
  async created() {
    await this.fetchAlerts();
  },
};
