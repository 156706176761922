






















































import { useRoute } from "@/utils";
import config from "../../config";
import userapi from "@api/User.api";
import querystring from "querystring";
import recordingsapi, { RecordingQuery } from "@api/Recording.api";
import { defineComponent, PropType, ref, watch } from "@vue/composition-api";
import RecordingApi from "@api/Recording.api";
import api from "@/api";

export default defineComponent({
  name: "SettingsDropdown",
  events: ["submit"],
  props: {
    addDeletedRecordings: {
      type: Function as PropType<(recordingIds: string[]) => void>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const showBulkDelete = ref(false);
    const route = useRoute();
    const recordingCount = ref(0);
    const download = async () => {
      const token = await userapi.token();
      const params = recordingsapi.makeApiQuery(route.value.query);
      params.jwt = token;
      params.offset = 0;
      params.limit = 100000;
      params.type = "recordings";
      const url =
        `${config.api}/api/v1/recordings/report?` +
        querystring.stringify(params);
      window.open(url, "_self");
    };
    const bulkDelete = () => {
      const query: RecordingQuery = {
        ...route.value.query,
        offset: 0,
        checkIsGroupAdmin: true,
      };
      if (query.limit) {
        delete query.limit;
      }
      return RecordingApi.bulkDelete(query).then((query) => {
        // refresh the page
        if (query.success) {
          props.addDeletedRecordings(query.result.ids);
        }
        emit("submit", route.value.query);
      });
    };
    watch(route, async () => {
      const count = await api.recording.queryCount(route.value.query);
      if (count.success) {
        showBulkDelete.value = count.result.count > 0;
        recordingCount.value = count.result.count;
      }
    });
    return { route, download, bulkDelete, showBulkDelete, recordingCount };
  },
});
