// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.right[data-v-02a326cd] {
  display: flex;
  margin-left: auto;
}
.green[data-v-02a326cd] {
  color: #28a745;
}
.red[data-v-02a326cd] {
  color: darkred;
}
`, "",{"version":3,"sources":["webpack://./src/components/IconLink.vue"],"names":[],"mappings":";AAoCA;EACA,aAAA;EACA,iBAAA;AACA;AAEA;EACA,cAAA;AACA;AAEA;EACA,cAAA;AACA","sourcesContent":["<template>\n  <b-link :to=\"link\" :class=\"position\">\n    <font-awesome-icon\n      :icon=\"icon\"\n      :class=\"colour\"\n      size=\"2x\"\n      style=\"cursor: pointer\"\n    />\n  </b-link>\n</template>\n\n<script>\nexport default {\n  name: \"IconLink\",\n  props: {\n    icon: {\n      type: Array,\n      required: true,\n    },\n    link: {\n      type: Object,\n      default: null,\n    },\n    colour: {\n      type: String,\n      default: \"green\",\n    },\n    position: {\n      type: String,\n      default: \"\",\n    },\n  },\n};\n</script>\n\n<style scoped>\n.right {\n  display: flex;\n  margin-left: auto;\n}\n\n.green {\n  color: #28a745;\n}\n\n.red {\n  color: darkred;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
