
























































































import Help from "@/components/Help.vue";
import DeviceLink from "@/components/DeviceLink.vue";
import GroupLink from "@/components/GroupLink.vue";
import MapWithPoints from "@/components/MapWithPoints.vue";

export default {
  name: "DevicesTab",
  components: {
    DeviceLink,
    GroupLink,
    Help,
    MapWithPoints,
  },
  props: {
    isGroupAdmin: { type: Boolean, default: false },
    devices: { type: Array, required: true },
    loading: { type: Boolean, default: false },
    groupName: { type: String, required: true },
  },
  methods: {
    sortBool(_v, _k, i) {
      return `${i.type}_${i.isHealthy}`;
    },
  },
  computed: {
    groupHasDevices() {
      return this.devices.length !== 0;
    },
    devicesForMap() {
      // Stations lat/lng as leaflet lat/lng objects
      return this.devices
        .filter((device) => device.location !== undefined)
        .map(({ deviceName, location }) => ({
          name: deviceName,
          location,
        }));
    },
    tableItems() {
      return this.devices.map((device) => ({
        ...device,
        _rowVariant: device.hasOwnProperty("isHealthy")
          ? device.isHealthy
            ? "okay"
            : "warn"
          : "empty",
      }));
    },
  },
};
