




































import { PropType } from "vue";
import { defineComponent, onMounted, ref, watch } from "@vue/composition-api";

import api from "@/api";
import { useRoute, useRouter, useState } from "@/utils";

import { RecordingType } from "@typedefs/api/consts";
import type { Dictionary } from "vue-router/types/router";
import { ApiRecordingResponse } from "@typedefs/api/recording";

enum QueryDirection {
  Next = "next",
  Previous = "previous",
}
type Query = Dictionary<string | (string | null)[]>;

export default defineComponent({
  name: "Playlist",
  props: {
    recordingDateTime: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    deleteRecording: {
      type: Function as PropType<() => Promise<void>>,
      required: true,
    },
    isGroupAdmin: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const [nextRecordings, setNextRecordings] = useState<{
      next: { id: number; query: Query } | null;
      prev: { id: number; query: Query } | null;
    }>({ next: null, prev: null });
    const stripQuery = (
      query: Query,
      remove = ["to", "from", "order", "type", "limit", "offset"]
    ) => {
      const newQuery: Query = {};
      for (const key in query) {
        if (remove.includes(key)) {
          continue;
        }
        newQuery[key] = query[key];
      }
      return newQuery;
    };

    const queryNextRecording = async (
      query: Query
    ): Promise<ApiRecordingResponse[]> => {
      try {
        const response = await api.recording.query(query);
        if (response.success) {
          const { result } = response;
          return result.rows;
        } else {
          throw response.result.messages;
        }
      } catch (error) {
        // console.error(error);
        return null;
      }
    };

    const getNextRecordingQuery = (
      direction: QueryDirection,
      limit = 1
    ): Query => {
      const currentQuery = route.value.query;
      const orderBy = (o: string) => JSON.stringify([["recordingDateTime", o]]);
      const [to, from, order] =
        direction === QueryDirection.Next
          ? [null, props.recordingDateTime, orderBy("ASC")]
          : [props.recordingDateTime, null, orderBy("DESC")];
      const query = {
        to,
        from,
        order,
        countAll: "false",
        limit: limit.toString(),
        type: RecordingType.Audio,
      };
      return { ...currentQuery, ...query };
    };

    const pushNextRecording = async (id: number, query: Query) => {
      if (route.value.params.id !== id.toString()) {
        router.push({
          path: `/recording/${id}`,
          query: stripQuery(query),
        });
      }
    };

    const navigateToNextRecording = async (direction: QueryDirection) => {
      const recording =
        direction === QueryDirection.Next
          ? nextRecordings.value.next
          : nextRecordings.value.prev;

      if (recording) {
        pushNextRecording(recording.id, recording.query);
      }
    };
    const isLoading = ref(true);
    const initNextRecordings = async () => {
      isLoading.value = true;
      const prevQuery = getNextRecordingQuery(QueryDirection.Previous);
      const nextQuery = getNextRecordingQuery(QueryDirection.Next);
      const prevRecs = await queryNextRecording(prevQuery);
      const nextRecs = await queryNextRecording(nextQuery);

      setNextRecordings({
        next:
          nextRecs.length > 0 ? { id: nextRecs[0].id, query: nextQuery } : null,
        prev:
          prevRecs.length > 0 ? { id: prevRecs[0].id, query: prevQuery } : null,
      });
      isLoading.value = false;
    };
    onMounted(() => {
      initNextRecordings();
    });
    watch(() => props.url, initNextRecordings);

    return {
      navigateToNextRecording,
      isLoading,
      QueryDirection,
      nextRecordings,
    };
  },
});
