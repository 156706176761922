





















import * as moment from "moment";
export default {
  name: "AudioBaitSummary",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatDate(date: string, formatStr: string): string {
      return moment(date).format(formatStr);
    },
    capitalizeFirst(value: string) {
      return value.charAt(0).toUpperCase() + value.substring(1);
    },
  },
};
