























import { PropType } from "vue";
type TagClass = "automatic" | "human" | "automatic human";
interface Tag {
  text: string;
  class: TagClass;
  order: number;
}

export default {
  name: "TagBadge",
  props: {
    tag: {
      type: Object as PropType<Tag>,
      required: true,
    },
  },
  computed: {
    tagLabel(): string {
      if (
        this.tag.text === "unidentified" &&
        this.tag.class.includes("human")
      ) {
        return "not identifiable";
      }
      return this.tag.text.replace(/-/g, " ");
    },
    tagTitle(): string {
      switch (this.tag.class) {
        case "automatic":
          return "Tagged by Cacophony AI";
        case "human":
          return "Tagged by human";
        case "automatic human":
          return "Tagged by Cacophony AI and human";
        default:
          return "";
      }
    },
  },
};
