import { render, staticRenderFns } from "./TagBadge.vue?vue&type=template&id=d0ae6922&scoped=true&"
import script from "./TagBadge.vue?vue&type=script&lang=ts&"
export * from "./TagBadge.vue?vue&type=script&lang=ts&"
import style0 from "./TagBadge.vue?vue&type=style&index=0&id=d0ae6922&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d0ae6922",
  null
  
)

export default component.exports