




















import ProgressRing from "@/components/ProgressRing.vue";
export default {
  name: "AudioPlayerButton",
  components: { ProgressRing },
  props: {
    srcGetter: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      playing: false,
      inited: false,
      loading: false,
      src: null,
      reset: null,
      duration: 0,
      localProgressZeroToOne: 0,
    };
  },
  beforeDestroy() {
    this.audio.removeEventListener("loadedmetadata", this.setDuration);
    this.audio.removeEventListener("timeupdate", this.updateProgress);
  },
  methods: {
    setDuration(): void {
      this.duration = this.audio.duration;
    },
    initPlayer(): void {
      this.audio.addEventListener("loadedmetadata", this.setDuration);
      if (this.audio.readyState > 0) {
        this.setDuration();
      }
      this.audio.addEventListener("timeupdate", this.updateProgress);
    },
    updateProgress(): void {
      // Set progress
      if (!this.duration) {
        this.setDuration();
      }
      if (this.duration) {
        this.currentTime = this.audio.currentTime;
        this.localProgressZeroToOne = this.currentTime / this.duration;
        if (this.currentTime === this.duration) {
          this.playing = false;
          this.stopSound();
        }
      }
    },
    toggleSound() {
      if (!this.inited) {
        this.initPlayer();
      }
      this.playing = !this.playing;
      if (this.playing) {
        this.playSound();
      } else {
        this.stopSound();
      }
    },
    async stopSound() {
      await this.audio.pause();
      this.audio.currentTime = 0;
      this.reset = setTimeout(() => {
        this.inited = false;
      }, 1000);
    },
    async playSound() {
      if (this.playing) {
        clearTimeout(this.reset);
        this.reset = null;
        this.loading = true;
        if (!this.src) {
          this.src = await this.srcGetter();
        }
        this.audio.src = this.src;
        this.audio.currentTime = 0;
        this.loading = false;
        this.inited = true;
        await this.audio.play();
      }
    },
  },
  computed: {
    audio(): HTMLAudioElement | undefined {
      return this.$refs.audioPlayer;
    },
  },
};
