// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
b-btn[data-v-79d6b340] {
  margin: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Analysis/ScaleChoice.vue"],"names":[],"mappings":";AAiCA;EACA,YAAA;AACA","sourcesContent":["<template>\n  <b-btn\n    :pressed=\"value\"\n    variant=\"outline-secondary\"\n    size=\"sm\"\n    @click=\"$emit('input', !value)\"\n  >\n    Log Scale\n  </b-btn>\n</template>\n\n<script>\nexport default {\n  name: \"ScaleChoice\",\n  props: {\n    value: {\n      type: Boolean,\n      required: true,\n    },\n  },\n  data: function () {\n    return {\n      types: [\n        { text: \"Log\", value: false },\n        { text: \"Log\", value: true },\n      ],\n    };\n  },\n  methods: {},\n};\n</script>\n\n<style scoped>\nb-btn {\n  margin: 20px;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
