

























































import User from "../api/User.api";

export default {
  name: "ResetPasswordView",
  props: {},
  data() {
    return {
      email: "",
      successMessage: null,
    };
  },
  computed: {},
  methods: {
    async onSubmit(evt) {
      this.successMessage = null;
      evt.preventDefault();
      const response = await User.reset(this.email);
      if (response.success) {
        this.successMessage = "An email has been sent to reset your password";
      } else {
        this.successMessage =
          "Password reset request failed, please try again later";
      }
    },
  },
};
