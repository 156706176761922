



























import * as moment from "moment";
import { VisitEvent } from "../api/visits";
export default {
  name: "EventSummary",
  props: {
    trackNumber: {
      type: Number,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    deviceId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    formatDate(date: string, formatStr: string): string {
      return moment(date).format(formatStr);
    },
    capitalizeFirst(value: string) {
      if (value) {
        return value.charAt(0).toUpperCase() + value.substring(1);
      } else {
        return "Probably Nothing";
      }
    },
    navigateToRecording(event, visEvent: VisitEvent) {
      if (!(event.metaKey || event.ctrlKey || event.shiftKey)) {
        this.$router.push({
          path: `recording/${visEvent.recID}/${visEvent.trackID}?device=${this.deviceId}`,
        });
      }
    },
  },
};
