var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"admin",attrs:{"fluid":""}},[_c('b-jumbotron',{staticClass:"jumbotron",attrs:{"fluid":""}},[_c('h1',[_vm._v("Your groups")]),_vm._v(" "),_c('p',{staticClass:"lead"},[_vm._v("\n      Groups link together devices with the users who can view their\n      recordings. Click on a group to see its devices and users.\n    ")])]),_vm._v(" "),_c('b-container',{staticClass:"groups-container"},[(_vm.isLoading)?_c('b-row',[_c('spinner')],1):_c('b-row',[_c('b-col',{staticClass:"col-md-6 col-12"},[_c('group-add'),_vm._v(" "),_c('div',{staticClass:"add-button-wrapper"},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.group-add",modifiers:{"group-add":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"add-button",attrs:{"variant":"primary","title":"Add a new group"}},[_c('font-awesome-icon',{attrs:{"icon":"plus","size":"xs"}}),_vm._v(" "),_c('span',[_vm._v("Create group")])],1)],1),_vm._v(" "),(_vm.hasGroups)?_c('div',{staticClass:"group-list-wrapper"},[_c('b-checkbox',{staticClass:"filter-option",model:{value:(_vm.showGroupsWithNoDevices),callback:function ($$v) {_vm.showGroupsWithNoDevices=$$v},expression:"showGroupsWithNoDevices"}},[_vm._v("Include groups with no devices")]),_vm._v(" "),_c('b-checkbox',{staticClass:"filter-option",model:{value:(_vm.showGroupsWithNoRecordings),callback:function ($$v) {_vm.showGroupsWithNoRecordings=$$v},expression:"showGroupsWithNoRecordings"}},[_vm._v("Include groups with no recordings")]),_vm._v(" "),_c('b-list-group',{attrs:{"data-cy":"groups-list"}},_vm._l((_vm.orderedGroups),function(ref){
              var groupName = ref.groupName;
              var devices = ref.devices;
              var lastThermalRecordingTime = ref.lastThermalRecordingTime;
              var lastAudioRecordingTime = ref.lastAudioRecordingTime;
return _c('b-list-group-item',{key:groupName,class:[
                'list-group-item',
                'list-group-item-action',
                { 'no-devices': devices.length === 0 } ],attrs:{"to":{
                name: 'group',
                params: { groupName: groupName, tabName: 'visits' },
              }}},[_c('span',[(lastThermalRecordingTime)?_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":"video","size":"xs"}}):_vm._e(),_vm._v(" "),(lastAudioRecordingTime)?_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":"music","size":"xs"}}):_vm._e(),_vm._v(" "),(!lastAudioRecordingTime && !lastThermalRecordingTime)?_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":"question","size":"xs"}}):_vm._e(),_vm._v(" "),_c('strong',[_vm._v(_vm._s(groupName))]),_vm._v(" "),(devices.length !== 0)?_c('span',[_vm._v("\n                  - "+_vm._s(devices.length || "No")+" device"),(devices.length !== 1)?_c('span',[_vm._v("s")]):_vm._e()]):_vm._e()],1),_vm._v(" "),_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":"chevron-right","size":"xs"}})],1)}),1)],1):_c('div',{staticClass:"col-12 col-lg-8"},[_c('b-card',{staticClass:"no-content-placeholder"},[_c('h5',[_vm._v("You don't belong to any groups yet")]),_vm._v(" "),_c('p',[_vm._v("\n              If you are setting up a device, create a group. All the devices\n              you manage will be linked together through this group, so choose\n              a name relating to your organisation, project or property.\n            ")])])],1)],1),_vm._v(" "),_c('b-col',{staticClass:"col-md-6 col-12"},[(!_vm.locationsLoading && _vm.groupsByLocation.length)?_c('MapWithPoints',{attrs:{"points":_vm.groupsByLocation,"height":500,"navigate-to-point":function (point) { return ({
              name: 'group',
              params: { groupName: point.group, tabName: 'visits' },
            }); }}}):(_vm.groupsByLocation.length)?_c('div',{staticClass:"map-loading"},[_c('b-spinner',{attrs:{"small":""}}),_vm._v(" "),_c('div',[_vm._v(" Loading group locations")])],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }