













































































































































































































































































































































import { PropType } from "vue";
import { produce } from "immer";
import {
  defineComponent,
  watch,
  computed,
  ref,
  onMounted,
} from "@vue/composition-api";

import api from "@api";
import store from "@/stores";
import { useState, UUIDv4 } from "@/utils";
import { TagColours } from "@/const";

import AudioPlayer from "../Audio/AudioPlayer.vue";
import TrackList from "../Audio/TrackList.vue";
import Playlist from "../Audio/Playlist.vue";
import LabelButtonGroup from "../Audio/LabelButtonGroup.vue";
import CacophonyIndexGraph from "../Audio/CacophonyIndexGraph.vue";
import RecordingProperties from "../Video/RecordingProperties.vue";
import MapWithPoints from "@/components/MapWithPoints.vue";
import Help from "@/components/Help.vue";

import { ApiTrackResponse, ApiTrackDataRequest } from "@typedefs/api/track";
import { ApiTrackTag, ApiTrackTagAttributes } from "@typedefs/api/trackTag";
import {
  ApiTrackTagRequest,
  ApiTrackTagResponse,
} from "@typedefs/api/trackTag";
import { ApiAudioRecordingResponse } from "@typedefs/api/recording";
import { TrackId } from "@typedefs/api/common";
import { RecordingProcessingState } from "@typedefs/api/consts";
import { ApiGroupResponse } from "@typedefs/api/group";
import { ApiRecordingTagRequest } from "@typedefs/api/tag";
import { getClassifications } from "../ClassificationsDropdown.vue";
import ClassificationsDropdown from "../ClassificationsDropdown.vue";

import { Option } from "./LayeredDropdown.vue";
import { reduce } from "lodash";

const flattenNodes = (
  acc: Record<string, { label: string; display: string; parents: string[] }>,
  node: Option,
  parents: string[]
) => {
  for (const child of node.children || []) {
    acc[child.label] = {
      label: child.label,
      display: child.display || child.label,
      parents: [...parents],
    };
    flattenNodes(acc, child, [...acc[child.label].parents, child.label]);
  }
  return acc;
};

export const getDisplayTags = (
  options: Option,
  track: ApiTrackResponse
): DisplayTag[] => {
  const labelToParent = {};
  const classifications = flattenNodes(labelToParent, options, []);
  let automaticTags = track.tags.filter(
    (tag) =>
      tag.automatic &&
      ((tag.data as any) === "Master" || tag.data.name === "Master")
  );
  const humanTags = track.tags.filter((tag) => !tag.automatic);
  let reducedHuman = {};

  humanTags.forEach((humanTag) => {
    let exists = false;
    const parents =
      humanTag.what in labelToParent
        ? labelToParent[humanTag.what].parents
        : [];

    for (const existingWhat of Object.keys(reducedHuman)) {
      if (existingWhat === humanTag.what) {
        exists = true;
        break;
      }
      const existingParents =
        existingWhat in labelToParent
          ? labelToParent[existingWhat].parents
          : [];
      if (existingParents.includes(humanTag.what)) {
        exists = true;
        break;
      }
      if (parents.includes(existingWhat)) {
        //remove existing what and add this
        delete reducedHuman[existingWhat];
      }
    }

    if (!exists) {
      reducedHuman[humanTag.what] = humanTag;
    }
  });
  reducedHuman = Object.values(reducedHuman);
  if (automaticTags && automaticTags.length > 0) {
    if (automaticTags.length > 1 && humanTags.length == 0) {
      automaticTags = automaticTags.filter(
        (tag) =>
          !automaticTags.find(
            (others) =>
              others != tag &&
              others.what in labelToParent &&
              labelToParent[others.what].parents.find(
                (parent) => parent === tag.what
              )
          )
      );
    }

    reducedHuman.sort((a, b) => {
      if (a.what === "bird") {
        return 2;
      } else if (b.what === "bird") {
        return -2;
      }
      return a.what <= b.what ? -1 : 1;
    });

    automaticTags.sort((a, b) => {
      if (a.what === "bird") {
        return 2;
      } else if (b.what === "bird") {
        return -2;
      }
      return a.what <= b.what ? -1 : 1;
    });

    if (humanTags.length > 0) {
      //tags which match or, matches an ai tag which is a parent of this tag but not a top level tag
      const confirmedTags = reducedHuman.filter(
        (tag) =>
          automaticTags.filter(
            (autoTag) =>
              autoTag.what === tag.what ||
              (tag.what !== "bird" &&
                tag.what in labelToParent &&
                automaticTags.find(
                  (autoTag) =>
                    autoTag.what in labelToParent &&
                    labelToParent[autoTag.what].parents.length > 0 &&
                    tag.what in labelToParent &&
                    labelToParent[tag.what].parents.find(
                      (parent) => parent === autoTag.what
                    )
                ))
          ).length > 0
      );

      if (confirmedTags.length > 0) {
        return [
          ...confirmedTags.map((confirmedTag) => ({
            ...confirmedTag,
            class: TagClass.Confirmed,
          })),
        ];
      } else {
        //might want another way of showing this information
        //filter any that aren't more specific of the human tag
        //automaticTags = automaticTags.filter(
        // (autoTag) =>
        //    autoTag.what in labelToParent &&
        //    labelToParent[autoTag.what].parents.find((parent) =>
        //      reducedHuman.find((humanTag) => parent === humanTag.what)
        //    )
        //);

        // check if all human tags are the same
        return [
          ...reducedHuman.map((humanTag) => ({
            ...humanTag,
            class: TagClass.Human,
          })),
          //...automaticTags.map((automaticTag) => ({
          //  ...automaticTag,
          //  class: TagClass.Denied,
          // })),
        ];
      }
    } else {
      return automaticTags.map((automaticTag) => ({
        ...automaticTag,
        class: TagClass.Automatic,
      }));
    }
  } else if (reducedHuman.length > 0) {
    return [
      ...reducedHuman.map((humanTag) => ({
        ...humanTag,
        class: TagClass.Human,
      })),
    ];
  } else {
    return [];
  }
};

export enum TagClass {
  Automatic = "automatic",
  Human = "human",
  Confirmed = "confirmed",
  Denied = "denied",
}

export interface DisplayTag extends ApiTrackTagResponse {
  class: TagClass;
}

export interface AudioTrack extends ApiTrackResponse {
  colour: string;
  displayTags: DisplayTag[];
  confirming: boolean;
  deleted: boolean;
  playEventId?: string;
}
export type AudioTracks = Map<TrackId, AudioTrack>;

const fetchAudioBuffer = async (url: string) => {
  const response = await fetch(url);
  const arrayBuffer = await response.blob();
  return arrayBuffer;
};

export default defineComponent({
  name: "AudioRecording",
  props: {
    recording: {
      type: Object as PropType<ApiAudioRecordingResponse>,
      required: true,
    },
    audioUrl: {
      type: String,
      required: true,
    },
    audioRawUrl: {
      type: String,
      required: true,
    },
  },
  components: {
    MapWithPoints,
    AudioPlayer,
    Playlist,
    TrackList,
    CacophonyIndexGraph,
    RecordingProperties,
    LabelButtonGroup,
    ClassificationsDropdown,
    Help,
  },
  setup(props, context) {
    const options = ref<Option>({ label: "", children: [] });

    const userName = store.state.User.userData.userName;
    const userId = store.state.User.userData.id;
    const [url, setUrl] = useState(
      // props.audioUrl ? props.audioUrl : props.audioRawUrl
      props.audioRawUrl ? props.audioRawUrl : props.audioUrl
    );
    const buffer = ref<Blob>(null);
    const [sampleRate, setSampleRate] = useState<number>(
      localStorage.getItem("audio-sample-rate")
        ? parseInt(localStorage.getItem("audio-sample-rate"))
        : 24000
    );
    watch(sampleRate, (currSampleRate) => {
      localStorage.setItem("audio-sample-rate", currSampleRate.toString());
    });

    const savedColour = localStorage.getItem("audio-colour");
    const [colour, setColour] = useState(savedColour ? savedColour : "cool");
    watch(colour, () => {
      // store the colour in local storage
      localStorage.setItem("audio-colour", colour.value);
    });

    const [deleted, setDeleted] = useState(false);
    watch(
      () => [props.audioUrl, props.audioRawUrl],
      async ([newUrl, newRawUrl]) => {
        setDeleted(false);
        // const url = newUrl ? newUrl : newRawUrl;
        const url = newRawUrl ? newRawUrl : newUrl;
        buffer.value = await fetchAudioBuffer(url);
        setUrl(url);
      }
    );

    const deletedStation = ref(false);
    const deleteRecording = async () => {
      const response = await api.recording.del(props.recording.id);
      if (response.success) {
        setDeleted(true);
        // check if station is now empty and delete if it is
        const response = await api.station.getStationById(
          props.recording.stationId
        );
        if (response.success) {
          const { station } = response.result;
          const res = await api.station.getStationRecordingsCount(station.id);
          if (res.success && res.result.count === 0) {
            //Prompt user to delete station

            const shouldDelete = await context.root.$bvModal.msgBoxConfirm(
              "This was the last recording on this station. Do you want to delete the station? This action cannot be undone.",
              {
                title: "Delete Station",
                okVariant: "danger",
                okTitle: "Delete",
                cancelTitle: "Cancel",
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true,
              }
            );
            if (shouldDelete) {
              await api.station.deleteStationById(station.id);
              deletedStation.value = true;
            }
          }
        }
      }
    };

    const undoDeleteRecording = async () => {
      const response = await api.recording.undelete(props.recording.id);
      if (response.success) {
        setDeleted(false);
      }
    };

    const isGroupAdmin = ref(false);
    const filterHuman = ref(false);

    const createAudioTrack = (
      track: ApiTrackResponse,
      index: number
    ): AudioTrack => {
      const displayTags = getDisplayTags(options.value, track);

      return {
        deleted: false,
        colour: TagColours[index % TagColours.length],
        displayTags,
        confirming: false,
        ...track,
        ...{ start: track.start ? track.start : 0 },
        ...{ end: track.end ? track.end : 0 },
      };
    };
    const showFilteredNoise = ref(false);
    const setFilteredNoise = (val: boolean) => {
      showFilteredNoise.value = val;
    };

    const mappedTracks = (tracks: ApiTrackResponse[]) =>
      new Map(
        tracks.map((track, index) => {
          const audioTrack = createAudioTrack(track, index);
          return [track.id, audioTrack];
        })
      );
    const filterTracks = (tracks: (ApiTrackResponse | AudioTrack)[]) => {
      const tags = filteredAudioTags.value ?? [];
      const filtered = tracks
        .filter(
          (track) =>
            !track.tags.some((tag) => {
              if (tag.automatic) {
                return tag.data.name === "Master"
                  ? tags.includes(tag.what)
                  : false;
              } else {
                tags.includes(tag.what);
              }
            }) || track.tags.some((tag) => !tag.automatic)
        )
        .filter((track) => showFilteredNoise.value || !track.filtered);
      return filtered;
    };

    const [tracks, setTracks] = useState<AudioTracks>(new Map());
    const displayTracks = computed(() => {
      return mappedTracks(filterTracks([...tracks.value.values()]));
    });
    const [selectedTrack, setSelectedTrack] = useState<AudioTrack>(null);

    const playTrack = (track?: AudioTrack) => {
      if (track) {
        const currTrack = tracks.value.get(track.id);
        setSelectedTrack(() => ({
          ...(currTrack ?? track),
          playEventId: UUIDv4(),
        }));
      } else {
        setSelectedTrack(null);
      }
    };

    const addTrack = async (track: AudioTrack): Promise<AudioTrack> => {
      try {
        const trackRequest: ApiTrackRequest = {
          data: {
            start_s: track.start,
            end_s: track.end,
            maxFreq: track.maxFreq,
            minFreq: track.minFreq,
            positions: [track.positions[1]],
            userId: userId,
            automatic: false,
          },
        };
        const response = await api.recording.addTrack(
          trackRequest,
          props.recording.id
        );

        if (response.success) {
          const id = response.result.trackId;
          const colour =
            track.colour && track.id !== -1
              ? track.colour
              : TagColours[tracks.value.size % TagColours.length];
          const newTrack = {
            ...track,
            id,
            colour,
            deleted: false,
          };
          setTracks((tracks) => {
            const track = tracks.get(id);
            tracks.set(
              id,
              produce(track, () => newTrack)
            );
          });
          return newTrack;
        } else {
          throw response.result;
        }
      } catch (error) {
        // console.error(error);
      }
    };
    const modifyTrack = (
      trackId: TrackId,
      trackChanges: Partial<AudioTrack>
    ): AudioTrack => {
      setTracks((draftTracks) => {
        const track = draftTracks.get(trackId);
        draftTracks.set(
          trackId,
          produce(track, () => ({
            ...track,
            ...trackChanges,
          }))
        );
      });
      return tracks.value.get(trackId) as AudioTrack;
    };

    const addTagToTrack = async (
      trackId: TrackId,
      what: string,
      automatic = false,
      confidence = 1,
      data: any = {},
      username = userName
    ): Promise<AudioTrack> => {
      const track = tracks.value.get(trackId);
      const tag: ApiTrackTagRequest = {
        what: what.toLowerCase(),
        automatic,
        confidence,
        ...(data && { data: JSON.stringify(data) }),
      };
      let shouldDelete = false;

      if (filterHuman.value && tag.what === "human") {
        shouldDelete = await context.root.$bvModal.msgBoxConfirm(
          "The group has privacy protection, adding this human tag will delete the recording. Are you sure you want to continue?",
          {
            title: "Privacy Protection",
            okVariant: "danger",
            okTitle: "Delete",
            cancelTitle: "Cancel",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        );
      }
      const response = await api.recording.replaceTrackTag(
        tag,
        props.recording.id,
        Number(trackId),
        tag.automatic
      );
      if (response.success) {
        const newTag = {
          ...tag,
          id: response.result.trackTagId ?? 0,
          trackId,
          data,
          userId,
          automatic,
          userName: username,
        } as ApiTrackTag;
        const currTags = track.tags.filter((tag) => tag.userId !== userId);
        const newTags = [...currTags, newTag];
        const taggedTrack = modifyTrack(trackId, {
          confirming: false,
          tags: newTags,
        });
        const displayTags = getDisplayTags(options.value, taggedTrack);
        const currTrack = modifyTrack(trackId, {
          displayTags,
          confirming: false,
        });
        if (selectedTrack.value && selectedTrack.value.id === trackId) {
          setSelectedTrack(() => currTrack);
        }
        storeCommonTag(what);
        setButtonLabels(createButtonLabels());
        if (shouldDelete) {
          await deleteRecording();
        }
        return currTrack;
      } else {
        return modifyTrack(trackId, {
          confirming: false,
        });
      }
    };

    const toggleAttributeToTrackTag = async (
      newAttr: Partial<ApiTrackTagAttributes>,
      trackId: TrackId,
      tagId: number
    ) => {
      try {
        const tag = tracks.value.get(trackId).tags.find((tag) => {
          if (tag.id === tagId) {
            return true;
          }
          return false;
        });
        if (!tag) {
          return;
        }
        const newAttrKeys = Object.keys(newAttr);
        const newAttrs = newAttrKeys.reduce((acc, key) => {
          if (tag.data[key] === newAttr[key]) {
            acc[key] = null;
          }
          return acc;
        }, newAttr);

        const response = await api.recording.updateTrackTag(
          newAttrs,
          props.recording.id,
          trackId,
          tagId
        );
        if (response.success) {
          setTracks((tracks) => {
            tracks.get(trackId).tags.forEach((tag) => {
              if (tag.id === tagId && typeof tag.data === "object") {
                tag.data = {
                  ...tag.data,
                  ...newAttrs,
                };
              }
            });
          });
        }
      } catch (error) {
        // console.error(error);
      }
    };

    const deleteTrackTag = async (
      trackId: TrackId,
      tagId: number
    ): Promise<AudioTrack> => {
      const track = tracks.value.get(trackId);
      if (track) {
        modifyTrack(trackId, {
          confirming: true,
        });
      }
      const response = await api.recording.deleteTrackTag(
        props.recording.id,
        trackId,
        tagId
      );
      if (response.success) {
        const currTags = track.tags.filter((tag) => tag.id !== tagId);
        const taggedTrack = modifyTrack(trackId, {
          tags: currTags,
        });
        const displayTags = getDisplayTags(options.value, taggedTrack);
        const currTrack = modifyTrack(trackId, {
          displayTags,
          confirming: false,
        });

        return currTrack;
      } else {
        return modifyTrack(trackId, {
          confirming: false,
        });
      }
    };

    const addTagToSelectedTrack = async (tag: string) => {
      if (selectedTrack.value) {
        let track = selectedTrack.value;
        if (selectedTrack.value.id === -1) {
          track = await addTrack(selectedTrack.value);
        }
        const newTrack = await addTagToTrack(track.id, tag);
        setSelectedTrack(newTrack);
      }
    };

    const deleteTagFromSelectedTrack = async () => {
      if (selectedTrack.value) {
        const userTag = selectedTrack.value.tags.find(
          (tag) => tag.userId === userId
        );
        if (userTag) {
          const newTrack = await deleteTrackTag(
            selectedTrack.value.id,
            userTag.id
          );
          // check if the track is now empty
          if (newTrack.tags.length === 0) {
            deleteTrack(newTrack.id, true);
          } else {
            setSelectedTrack(newTrack);
          }
        }
      }
    };

    const updateTrack = async (
      trackId: TrackId,
      trackData: ApiTrackDataRequest
    ) => {
      const response = await api.recording.updateTrack(
        trackId,
        props.recording.id,
        trackData
      );
      if (response.success) {
        // update local state
        setTracks((draftTracks) => {
          const track = draftTracks.get(trackId);

          draftTracks.set(
            trackId,
            produce(track, () => ({
              ...track,
              ...trackData,
              start: trackData.start_s,
              end: trackData.end_s,
            }))
          );
        });
        return { success: true };
      } else {
        return { success: false };
      }
    };

    const deleteTrack = async (trackId: TrackId, permanent = false) => {
      try {
        const response = await api.recording.deleteTrack(
          trackId,
          props.recording.id,
          true
        );
        if (response.success) {
          if (permanent) {
            setTracks((tracks) => {
              const newTracks = produce(tracks, (draftTracks) => {
                draftTracks.delete(trackId);
              });
              return newTracks;
            });
          } else {
            modifyTrack(trackId, {
              deleted: true,
            });
          }
          if (selectedTrack.value?.id === trackId) {
            setSelectedTrack(null);
          }
        } else {
          throw response.result;
        }
      } catch (error) {
        // console.error(error);
      }
    };

    const undoDeleteTrack = async (trackId: TrackId) => {
      try {
        const response = await api.recording.undeleteTrack(
          trackId,
          props.recording.id
        );
        if (response.success) {
          modifyTrack(trackId, {
            deleted: false,
          });
        } else {
          throw response.result;
        }
      } catch (error) {
        // console.error(error);
      }
    };

    const [cacophonyIndex, setCacophonyIndex] = useState(
      props.recording.cacophonyIndex
    );
    const showCacophonyIndex = ref(false);
    const group = ref<ApiGroupResponse>(null);
    const filteredAudioTags = ref<string[]>([]);

    watch(tracks, () => {
      if (selectedTrack.value) {
        setSelectedTrack(tracks.value.get(selectedTrack.value.id));
      }
    });

    const createButtonLabels = () => {
      const maxBirdButtons = 6;
      const fixedLabels = ["Bird", "Human", "Unknown"];
      const otherLabels = fixedLabels.map((label: string) => ({
        label,
        pinned: false,
      }));

      const storedCommonTags: { label: string; pinned: boolean }[] =
        Object.values(JSON.parse(localStorage.getItem("commonTags")) ?? {})
          .filter(
            (tag: { what: string }) =>
              !fixedLabels.some((label) => {
                return label.toLowerCase() === tag.what.toLowerCase();
              })
          )
          .sort((a: { freq: number }, b: { freq: number }) => b.freq - a.freq)
          // sort those that are pinned first
          .sort((a: { pinned: boolean }, b: { pinned: boolean }) => {
            if (a.pinned && !b.pinned) {
              return -1;
            } else if (!a.pinned && b.pinned) {
              return 1;
            } else {
              return 0;
            }
          })
          .map((bird: { what: string; pinned: boolean }) => ({
            label: bird.what.toLowerCase(),
            pinned: bird.pinned,
          }));

      const pinnedBirdLabels = storedCommonTags.filter((bird) => bird.pinned);
      const unpinnedBirdLabels = storedCommonTags.filter(
        (bird) => !bird.pinned
      );
      const commonBirdLabels = [
        "Morepork",
        "Kiwi",
        "Kereru",
        "Tui",
        "Kea",
        "Bellbird",
      ]
        .filter(
          (val: string) =>
            !storedCommonTags.find(
              (bird) => bird.label.toLowerCase() === val.toLowerCase()
            )
        )
        .map((label: string) => ({ label, pinned: false }));

      const amountToRemove = Math.min(maxBirdButtons, storedCommonTags.length);
      const diffToMax = maxBirdButtons - amountToRemove;
      const commonTags = [
        ...pinnedBirdLabels,
        ...unpinnedBirdLabels.splice(0, amountToRemove),
        ...commonBirdLabels.splice(0, diffToMax),
      ];

      const labels = [...commonTags, ...otherLabels];
      return labels;
    };
    const [buttonLabels, setButtonLabels] = useState(createButtonLabels());
    const [selectedLabel, setSelectedLabel] = useState<string>("");
    const usersTag = computed(() => {
      if (selectedTrack.value) {
        return selectedTrack.value.tags.find((tag) => tag.userId === userId);
      } else {
        return null;
      }
    });
    watch(selectedTrack, () => {
      if (selectedTrack.value) {
        const tag = selectedTrack.value.tags.find((tag) => {
          if (tag.userId === userId) {
            return true;
          }
          return false;
        });
        if (tag) {
          const capitalizedTag =
            tag.what.charAt(0).toUpperCase() + tag.what.slice(1);
          setSelectedLabel(capitalizedTag);
        } else {
          setSelectedLabel("");
        }
      } else {
        setSelectedLabel("");
      }
    });

    const storeCommonTag = (bird: string, togglePin = false, freq = 1) => {
      bird = bird.toLowerCase();
      const commonTags = JSON.parse(localStorage.getItem("commonTags")) ?? {};
      const newBird = commonTags[bird]
        ? commonTags[bird]
        : { what: bird, freq: 0, pinned: false };
      newBird.freq += freq;
      if (togglePin) {
        newBird.pinned = !newBird.pinned;
      }
      commonTags[bird] = newBird;
      localStorage.setItem("commonTags", JSON.stringify(commonTags));
    };

    const togglePinTag = (label: string) => {
      storeCommonTag(label, true, 0);
      setButtonLabels(createButtonLabels());
    };

    onMounted(async () => {
      options.value = (await getClassifications()) as Option;

      buffer.value = await fetchAudioBuffer(url.value);
      const response = await api.groups.getGroupById(props.recording.groupId);
      if (response.success) {
        group.value = response.result.group;
        const settings = response.result.group.settings;
        if (settings) {
          filterHuman.value = settings.filterHuman ?? false;
          filteredAudioTags.value = settings.filteredAudioTags ?? [];
        }
        isGroupAdmin.value = response.result.group.admin;
      }
      watch(filteredAudioTags, () => {
        const currTrack = selectedTrack.value;
        if (currTrack && !tracks.value.has(currTrack.id)) {
          setSelectedTrack(null);
        }
      });
      watch(
        () => [props.recording],
        () => {
          setTracks(mappedTracks(props.recording.tracks));
          setSelectedTrack(null);
          setCacophonyIndex(props.recording.cacophonyIndex);
        },
        {
          immediate: true,
        }
      );
    });

    const isQueued = computed(() => {
      const state = props.recording.processingState.toLowerCase();
      return (
        (state === RecordingProcessingState.Analyse ||
          state === RecordingProcessingState.AnalyseThermal ||
          state === RecordingProcessingState.Tracking ||
          state === RecordingProcessingState.Reprocess) &&
        !props.recording.processing
      );
    });

    const updateGroupFilterTags = async (tags: string[]) => {
      if (group.value && isGroupAdmin.value) {
        const res = await api.groups.updateGroupSettings(group.value.id, {
          filteredAudioTags: tags,
        });
        if (res.success) {
          filteredAudioTags.value = tags;
        }
      }
    };

    const formatDateStr = (date: string) => {
      const dateObj = new Date(date);
      const day = dateObj.getDate();
      const month = dateObj.getMonth() + 1;
      const year = dateObj.getFullYear();
      const hour = dateObj.getHours();
      const min = dateObj.getMinutes();
      return `${day}/${month}/${year} ${hour}:${min
        .toString()
        .padStart(2, "0")}`;
    };

    const tags = ["cool", "requires review"];
    const currComment = ref("");
    const currTag = ref(null);
    const comments = ref<
      {
        id: string;
        taggerId: number;
        tag: string;
        comment?: string;
        tagger: string;
        date: string;
      }[]
    >([]);
    watch(
      () => props.recording,
      (recording) => {
        comments.value = recording.tags.map((tag) => ({
          id: tag.id.toString(),
          taggerId: tag.taggerId,
          tag: tag.detail,
          comment: tag.comment,
          tagger: tag.automatic ? "Automatic" : tag.taggerName,
          date: formatDateStr(tag.createdAt),
        }));
      },
      { immediate: true }
    );
    const addRecordingTag = async () => {
      const detail = currTag.value ?? "note";
      const comment = currComment.value ?? undefined;
      const tagReq: ApiRecordingTagRequest = {
        detail,
        confidence: 1,
        comment,
        automatic: false,
      };

      const res = await api.recording.addRecordingTag(
        tagReq,
        props.recording.id
      );

      if (res.success) {
        const newComment = {
          id: res.result.tagId.toString(),
          tag: detail,
          comment,
          tagger: userName,
          taggerId: userId,
          date: formatDateStr(new Date().toISOString()),
        };
        comments.value = [...comments.value, newComment];
        currComment.value = "";
        currTag.value = null;
      }
    };

    const deleteComment = async (id: string) => {
      const res = await api.recording.deleteRecordingTag(
        Number(id),
        props.recording.id
      );

      if (res.success) {
        comments.value = comments.value.filter((comment) => comment.id !== id);
      }
    };

    return {
      url,
      buffer,
      labels: buttonLabels,
      cacophonyIndex,
      showCacophonyIndex,
      deleted,
      tracks: displayTracks,
      isGroupAdmin,
      isQueued,
      selectedTrack,
      selectedLabel,
      showFilteredNoise,
      usersTag,
      deletedStation,
      sampleRate,
      setSampleRate,
      colour,
      setColour,
      playTrack,
      togglePinTag,
      toggleAttributeToTrackTag,
      tags,
      comments,
      currTag,
      currComment,
      deleteComment,
      addRecordingTag,
      addTagToSelectedTrack,
      addTagToTrack,
      setFilteredNoise,
      addTrack,
      deleteTrack,
      updateTrack,
      deleteTrackTag,
      deleteTagFromSelectedTrack,
      deleteRecording,
      undoDeleteRecording,
      undoDeleteTrack,
      updateGroupFilterTags,
      group,
      userId,
      filteredAudioTags,
    };
  },
});
