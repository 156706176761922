















import { ref, defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "DropdownMenu",
  setup() {
    // State to manage the open/closed state of the dropdown
    const isOpen = ref(false);

    // Function to toggle the dropdown open/closed
    const toggleDropdown = () => {
      isOpen.value = !isOpen.value;
    };

    return {
      isOpen,
      toggleDropdown,
    };
  },
});
