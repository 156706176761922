// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/login-bg.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* b-container for both login and register */
.sign-wrapper .form-row {
    min-height: 100svh;
}

.container.sign-wrapper {
    max-width: 100%; /* Make it take the full width of the page */
}

@media (min-width: 576px) { /* Show bg image only if the device is larger than a phone */
    .sign-wrapper {
        background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
        background-position: center center;
        background-size: cover;
    }
}

.sign-form-wrapper .logo {
    display: block;
    margin: 0 auto;
}

/* wraps form */
.sign-form-wrapper {
    width: 100%;
}

@media (min-width: 576px) {
    .sign-form-wrapper {
        padding: 3rem 1.6rem 1rem;
    }
}

`, "",{"version":3,"sources":["webpack://./src/styles/login-register.css"],"names":[],"mappings":"AAAA,4CAA4C;AAC5C;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe,EAAE,4CAA4C;AACjE;;AAEA,4BAA4B,4DAA4D;IACpF;QACI,yDAA+C;QAC/C,kCAAkC;QAClC,sBAAsB;IAC1B;AACJ;;AAEA;IACI,cAAc;IACd,cAAc;AAClB;;AAEA,eAAe;AACf;IACI,WAAW;AACf;;AAEA;IACI;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":["/* b-container for both login and register */\n.sign-wrapper .form-row {\n    min-height: 100svh;\n}\n\n.container.sign-wrapper {\n    max-width: 100%; /* Make it take the full width of the page */\n}\n\n@media (min-width: 576px) { /* Show bg image only if the device is larger than a phone */\n    .sign-wrapper {\n        background-image: url('../assets/login-bg.jpg');\n        background-position: center center;\n        background-size: cover;\n    }\n}\n\n.sign-form-wrapper .logo {\n    display: block;\n    margin: 0 auto;\n}\n\n/* wraps form */\n.sign-form-wrapper {\n    width: 100%;\n}\n\n@media (min-width: 576px) {\n    .sign-form-wrapper {\n        padding: 3rem 1.6rem 1rem;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
