





















































import { mapState } from "vuex";
import DeviceDetail from "../components/Devices/DeviceDetail.vue";
import Spinner from "../components/Spinner.vue";
import api from "../api";
import { ApiDeviceResponse } from "@typedefs/api/device";
import { ApiGroupResponse } from "@typedefs/api/group";
import GroupLink from "@/components/GroupLink.vue";
import DeviceLink from "@/components/DeviceLink.vue";
import MapWithPoints from "@/components/MapWithPoints.vue";
import { isViewingAsOtherUser } from "@/components/NavBar.vue";
import { shouldViewAsSuperUser } from "@/utils";

interface DeviceViewData {
  device: ApiDeviceResponse;
  group: ApiGroupResponse;
}

export default {
  name: "DeviceView",
  components: { DeviceLink, GroupLink, DeviceDetail, Spinner, MapWithPoints },
  computed: {
    ...mapState({
      currentUser: (state) => (state as any).User.userData,
    }),
    deviceName() {
      return this.$route.params.deviceName;
    },
    groupName() {
      return this.$route.params.groupName;
    },
    userIsSuperUserAndViewingAsSuperUser() {
      return (
        this.currentUser.globalPermission === "write" &&
        (isViewingAsOtherUser() || shouldViewAsSuperUser())
      );
    },
    userIsMemberOfGroup(): boolean {
      return this.userIsSuperUserAndViewingAsSuperUser || !!this.group;
    },
    userIsGroupAdmin() {
      return (
        this.userIsSuperUserAndViewingAsSuperUser ||
        (this.group && this.group.admin)
      );
    },
  },
  data(): Record<string, any> & DeviceViewData {
    return {
      loadedDevice: false,
      device: null,
      group: null,
      softwareDetails: { message: "Retrieving version information..." },
    };
  },
  watch: {
    $route() {
      const nextDevice = this.deviceName;
      if (nextDevice !== this.device.deviceName) {
        // Only if the device changed.
        this.queryDevice();
      }
    },
  },
  created() {
    this.queryDevice();
  },
  methods: {
    async queryDevice() {
      this.loadedDevice = false;
      const [groupResponse, deviceResponse] = await Promise.all([
        api.groups.getGroup(this.groupName),
        api.device.getDevice(this.groupName, this.deviceName, true),
      ]);
      if (groupResponse.success) {
        this.group = groupResponse.result.group;
      }
      if (deviceResponse.success) {
        this.device = deviceResponse.result.device;
        await this.getSoftwareDetails(this.device.id);
      }
      this.loadedDevice = true;
    },
    async getSoftwareDetails(deviceId: number) {
      try {
        const { result } = await api.device.getLatestSoftwareVersion(deviceId);
        if (result.rows.length > 0) {
          this.softwareDetails.message = "Success";
          this.softwareDetails.result = result.rows[0];
        } else {
          this.softwareDetails = {
            message: "No version information is available for this device.",
          };
        }
      } catch (e) {
        // ...
      }
    },
  },
};
