












































































































































































































































































import * as moment from "moment";
import { Visit, DayVisits } from "../api/visits";
import DefaultLabels, { imgSrc } from "../const";
import EventSummary from "../components/EventSummary.vue";
import AudioSummary from "../components/AudioBaitSummary.vue";
import QueryRecordings from "../components/QueryRecordings/QueryRecordings.vue";
import api from "../api";
import { RecordingQuery, VisitsQueryResult } from "../api/Recording.api";
export default {
  name: "VisitsView",
  components: { QueryRecordings, EventSummary, AudioSummary },
  data() {
    return {
      queryParams: {},
      showInfo: this.isInfoShown(),
      infoMessage: `A "visit" is multiple thermal video tracks that have been combined because they are likely to be due to the appearance of a single animal. Each visit can be expanded by clicking on it to show the tracks which it is made up from.`,
      unknownHelp: `Humanly confirmed as unidentifiable`,
      nothingHelp: `AI is not sure if this is an animal or not`,
      unidentifiedHelp: `AI is sure there is an animal but doesn't know what it is`,
      tableDateTimeFormat: "L LTS",
      tableDateFormat: "DD MMM",
      tableTimeFormat: "LTS",
      currentQueryDescription: null,
      nextQueryDescription: null,
      eventMaxTimeSeconds: 60 * 10,
      searchPanelIsCollapsed: true,
      queryPending: false,
      deviceSummary: {},
      visits: [],
      loadingMore: false,
      fields: [
        { key: "what", label: "Animal" },
        { key: "start", label: "First Visit" },
        { key: "end", label: "Last Visit" },
        { key: "visits", label: "Visits" },
      ],
      visitFields: [
        {
          key: "what",
          label: "",
        },
        { key: "device", label: "Device" },
        { key: "date", label: "Date" },
        { key: "start", label: "Visit Start" },
        { key: "end", label: "End" },
        { key: "events", label: "#" },
      ],
      visitEventFields: [
        { key: "recording", label: "Recording" },
        { key: "track", label: "Track" },
        { key: "start", label: "Event Start" },
        { key: "confidence", label: "Confidence" },
        { key: "unidentified", label: "Assumed" },
      ],
      offset: 0,
      loadText: "Load More Visits",
      canLoadMore: true,
      visitLimit: 300,
      noQueryYet: true,
    };
  },
  computed: {
    countMessage() {
      if (this.visits.length > 0) {
        let visit_s;
        if (this.visits.length > 1) {
          visit_s = "visits";
        } else {
          visit_s = "visit";
        }
        const end = this.canLoadMore
          ? "found so far.  (There are more within search period)"
          : "found";
        return `${this.visits.length} ${visit_s} ${end}`;
      } else {
        return "No Visits";
      }
    },
    visitsByDayAndHour() {
      const visitsByDay = [];
      let currentDay = null;

      for (const item of this.visits) {
        if (!currentDay || !currentDay.isSameDay(item)) {
          currentDay = new DayVisits(item);
          visitsByDay.push(currentDay);
        } else {
          currentDay.addVisit(item);
        }
      }
      return visitsByDay;
    },
  },
  methods: {
    imgSrc,
    visitWhat(what: string): string {
      return this.capitalizeFirst(what);
    },
    whatToolTip(what: string): string {
      if (what === "null") {
        return this.nothingHelp;
      } else if (!what) {
        return this.unidentifiedHelp;
      } else if (what === DefaultLabels.unknownLabel.value) {
        return this.unknownHelp;
      }
      return `Track has been tagged as ${what}`;
    },
    summaryWhat(what: string): string {
      if (what === "null") {
        return "Probably not an Animal";
      } else if (!what) {
        return "( Unidentified ) Animal";
      }
      return this.capitalizeFirst(what);
    },
    capitalizeFirst(value: string) {
      if (value) {
        return value.charAt(0).toUpperCase() + value.substring(1);
      } else {
        return "Probably Nothing";
      }
    },
    formatDate(date: string, formatStr: string): string {
      return moment(date).format(formatStr);
    },
    isInfoShown() {
      return localStorage.getItem("visitInfo") != "0";
    },
    infoDismissed() {
      localStorage.setItem("visitInfo", "0");
    },
    isAudioBait(event: any) {
      return event.hasOwnProperty("DeviceId");
    },
    // sorts in descending order visit events (audio bait and tracks) grouped by recordings
    sortEventsByRec(visit: Visit) {
      const eventsByRec = [];
      let recEvent;
      let recID;

      var audioBaitEvents = visit.audioBaitEvents.slice();
      audioBaitEvents.sort(function (a, b) {
        return moment(a.dateTime) > moment(b.dateTime) ? 1 : -1;
      });
      let audioevent = audioBaitEvents.pop();
      let audioTime;
      if (audioevent) {
        audioTime = moment(audioevent.dateTime);
      }
      let audioBaitBefore = false;
      let trackNum = 0;
      for (const item of visit.events) {
        audioBaitBefore = audioTime && audioTime.isAfter(moment(item.start));
        // new recording
        if (!recID || item.recID != recID) {
          recID = item.recID;
          recEvent = { recStart: item.recStart, tracks: 0, events: [] };
          eventsByRec.push(recEvent);
          trackNum = 0;
        }

        // add all audio bait events before this recording
        while (audioBaitBefore) {
          recEvent.events.push(audioevent);
          audioevent = audioBaitEvents.pop();
          audioTime = null;
          if (audioevent) {
            audioTime = moment(audioevent.dateTime);
          }
          audioBaitBefore = audioTime && audioTime.isAfter(moment(item.start));
        }
        item.trackNumber = trackNum;
        recEvent.events.push(item);
        recEvent.tracks += 1;
        trackNum += 1;
      }

      // add remaining events
      if (audioevent) {
        audioBaitEvents.push(audioevent);
      }
      recEvent.events.splice(recEvent.events.length, 0, ...audioBaitEvents);
      return eventsByRec;
    },
    expandAdditionalInfo(row) {
      this.$set(row, "_showDetails", !row._showDetails);
    },
    setQuery(query: RecordingQuery) {
      this.$router
        .push({
          path: "visits",
          query,
        })
        .catch(() => {});
    },
    querysMounted(query: RecordingQuery) {
      this.setQuery(query);
      if (query && query.device && query.device.length !== 0) {
        this.searchPanelIsCollapsed = true;
        this.submitNewQuery(query);
      }
    },
    submitNewQuery(whereQuery: RecordingQuery) {
      this.setQuery(whereQuery);
      this.getVisits(whereQuery, true);
    },
    saveNextQueryDescription(description) {
      this.nextQueryDescription = description;
    },
    loadMoreVisits() {
      this.loadingMore = true;
      const query: RecordingQuery = this.queryParams;
      query.offset = this.offset;
      this.getVisits(query, false);
      this.loadingMore = false;
    },
    getVisits: async function (whereQuery: RecordingQuery, newQuery: boolean) {
      // Extract query information
      this.noQueryYet = false;
      if (newQuery) {
        this.offset = 0;
        this.queryPending = true;
      }

      whereQuery.limit = this.visitLimit;
      const { result, success } = await api.recording.queryVisits(whereQuery);
      this.currentQueryDescription = this.nextQueryDescription;
      if (!success) {
        result.messages &&
          result.messages.forEach((message) => {
            this.$store.dispatch("Messaging/WARN", message);
          });
        return;
      }
      this.currentQueryDescription = this.nextQueryDescription;
      if (newQuery) {
        this.visits = [];
        this.deviceSummary = {};
        this.offset = 0;
        this.canLoadMore = true;
        this.queryPending = false;
      }
      this.processVisits(result);
    },
    processVisits(result: VisitsQueryResult) {
      if (result.numRecordings === 0) {
        this.canLoadMore = false;
        return;
      }
      this.offset = result.queryOffset;
      this.canLoadMore = result.hasMoreVisits;

      const summary = result.summary;
      const filtered = result.visits.filter(
        (visit: Visit): boolean =>
          visit.events.length > 0 &&
          visit.what != DefaultLabels.birdLabel.value &&
          visit.what != DefaultLabels.falsePositiveLabel.value
      );

      this.visits.push(...filtered);
      for (const devId of Object.keys(summary)) {
        const newSummary = summary[devId];
        if (newSummary.hasOwnProperty(DefaultLabels.birdLabel.value)) {
          delete newSummary[DefaultLabels.birdLabel.value];
        }
        if (newSummary.hasOwnProperty(DefaultLabels.falsePositiveLabel.value)) {
          delete newSummary[DefaultLabels.falsePositiveLabel.value];
        }
        // filter out bird and false positive
        if (!(devId in this.deviceSummary)) {
          this.deviceSummary[devId] = newSummary;
          continue;
        }
        const existingSummary = this.deviceSummary[devId];
        for (const [animal, animalSummary] of Object.entries(newSummary)) {
          if (animal in existingSummary) {
            // merge
            const existingAnimalSummary = existingSummary[animal];
            existingAnimalSummary.visitCount += animalSummary.visitCount;
            existingAnimalSummary.eventCount += animalSummary.eventCount;
            if (animalSummary.start < existingAnimalSummary.start) {
              existingAnimalSummary.start = animalSummary.start;
            }
            if (animalSummary.end > existingAnimalSummary.end) {
              existingAnimalSummary.end = animalSummary.end;
            }
          } else {
            existingSummary[animal] = animalSummary;
          }
        }
      }

      this.visits = this.visits.sort(function (a, b) {
        if (a.start === b.start) {
          return a.id - b.id;
        }
        return a.start < b.start ? 1 : -1;
      });
    },
  },
};
