



























































































































import RecordingSummary from "@/components/RecordingSummary.vue";
import {
  toNZDateString,
  startOfDay,
  startOfHour,
  toStringTodayYesterdayOrDate,
} from "@/helpers/datetime";
import { RecordingType } from "@typedefs/api/consts";
import {
  ApiAudioRecordingResponse,
  ApiThermalRecordingResponse,
  CacophonyIndex,
} from "@typedefs/api/recording";
import { LatLng } from "@typedefs/api/common";
import { ApiRecordingTagResponse } from "@typedefs/api/tag";
import { FILTERED_TOOLTIP } from "../const";

const parseLocation = (location: LatLng): string => {
  if (location && typeof location === "object") {
    const latitude = location.lat;
    const longitude = location.lng;
    return latitude.toFixed(5) + ", " + longitude.toFixed(5);
  } else {
    return "(unknown)";
  }
};

const parseProcessingState = (result: string): string => {
  if (!result) {
    return "";
  }
  const string = result.toLowerCase();
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export interface IntermediateDisplayTag {
  taggerIds: number[];
  automatic: boolean;
  human: boolean;
}

export interface DisplayTag {
  taggerIds: number[];
  automatic: boolean;
  class: "human" | "automatic" | "automatic human";
  human: boolean;
  order: number;
}

const addToListOfTags = (
  allTags: Record<string, IntermediateDisplayTag>,
  tagName: string,
  isAutomatic: boolean,
  taggerId: number | null
) => {
  const tag = allTags[tagName] || {
    taggerIds: [],
    automatic: false,
    human: false,
  };
  if (taggerId && !tag.taggerIds.includes(taggerId)) {
    tag.taggerIds.push(taggerId);
  }
  if (isAutomatic) {
    tag.automatic = true;
  } else {
    tag.human = true;
  }
  allTags[tagName] = tag;
};

const collateTags = (tags: ApiRecordingTagResponse[]): DisplayTag[] => {
  // Build a collection of tagItems - one per animal
  const tagItems: Record<string, DisplayTag> = {};
  tags.forEach((tag) => {
    const tagName = tag.what || tag.detail; //tag.animal === null ? tag.event : tag.animal;
    const taggerId = tag.taggerId;
    addToListOfTags(tagItems, tagName, tag.automatic, taggerId);
  });

  // Use automatic and human status to create an ordered array of objects
  // suitable for parsing into coloured spans
  const result = [];
  for (let animal of Object.keys(tagItems).sort()) {
    const tagItem = tagItems[animal];
    let subOrder = 0;
    if (animal === "false positive") {
      subOrder = 3;
    } else if (animal === "multiple animals") {
      animal = "multiple";
      subOrder = 2;
    } else if (animal === "unidentified") {
      animal = "?";
      subOrder = 1;
    }

    if (tagItem.automatic && tagItem.human) {
      result.push({
        text: animal,
        class: "automatic human",
        taggerIds: tagItem.taggerIds,
        order: subOrder,
      });
    } else if (tagItem.human) {
      result.push({
        text: animal,
        class: "human",
        taggerIds: tagItem.taggerIds,
        order: 10 + subOrder,
      });
    } else if (tagItem.automatic) {
      result.push({
        text: animal,
        class: "automatic",
        order: 20 + subOrder,
      });
    }
  }
  return result;
};

const FILTERED_MAX = 100;
interface ItemData {
  kind: "dataRow" | "dataSeparator";
  id: number;
  type: RecordingType;
  deviceName: string;
  groupName: string;
  stationName?: string;
  cacophonyIndex?: CacophonyIndex[];
  stationId?: number;
  location: string;
  dateObj: Date;
  date: string;
  time: string;
  duration: number;
  recTags: DisplayTag[];
  batteryLevel: number | null;
  trackCount: number;
  processingState: string;
  processing: boolean;
  tracks: any[];
  redacted: boolean;
}

export default {
  name: "RecordingsList",
  components: { RecordingSummary },
  props: {
    recordings: {
      type: Array,
      required: true,
    },
    showCards: {
      type: Boolean,
      default: true,
    },
    queryPending: {
      type: Boolean,
      required: true,
    },
    viewRecordingQuery: {
      type: Object,
      default: () => ({}),
    },
    allLoaded: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    showFiltered() {
      if (this.filteredCount == this.recordings.length) {
        if (this.recordings.length < FILTERED_MAX) {
          this.$emit("load-more");
        } else if (!this.showFiltered) {
          // not showing any recordings but tried the first 100
          // give the user a button to load more
          this.loadButton = true;
        }
      } else {
        this.loadButton = false;
      }
      if (this.filteredCount > 0) {
        const scroller =
          this.$refs["list-container"].parentElement.parentElement;
        scroller.scrollTop = 0;
      }
    },
    showCards() {
      this.$refs["list-container"].style.height = "auto";
    },
    recordings() {
      let prevDate = null;
      const recordings = this.recordings as (
        | ApiThermalRecordingResponse
        | ApiAudioRecordingResponse
      )[];
      if (recordings.length === 0) {
        this.tableItems = [];
        this.recordingsChunkedByDayAndHour = [];
        this.loadedRecordingsCount = 0;
        return;
      }
      // Slice from last recordings count, so we're only processing new recordings.
      const newRecordings = recordings.slice(this.loadedRecordingsCount);
      this.loadedRecordingsCount = this.recordings.length;
      const items = [];

      for (const recording of newRecordings) {
        const thisDate = new Date(recording.recordingDateTime);
        if (
          prevDate === null ||
          startOfDay(thisDate).getTime() !== startOfDay(prevDate).getTime()
        ) {
          items.push({
            kind: "dataSeparator",
            hour: thisDate,
            date: thisDate,
          });
        } else if (
          startOfHour(thisDate).getTime() !== startOfHour(prevDate).getTime()
        ) {
          items.push({
            kind: "dataSeparator",
            hour: thisDate,
          });
        }
        prevDate = thisDate;
        const itemData: ItemData = {
          kind: "dataRow",
          id: recording.id,
          type: recording.type,
          deviceName: recording.deviceName,
          groupName: recording.groupName,
          cacophonyIndex: (recording as ApiAudioRecordingResponse)
            .cacophonyIndex,
          location: parseLocation(recording.location),
          dateObj: thisDate,
          date: toNZDateString(thisDate),
          time: thisDate.toLocaleTimeString(),
          duration: recording.duration,
          tracks: recording.tracks,
          recTags: collateTags(recording.tags),
          batteryLevel: (recording as ApiAudioRecordingResponse).batteryLevel,
          trackCount: recording.tracks.length,
          processingState: parseProcessingState(recording.processingState),
          processing: recording.processing === true,
          stationName: recording.stationName,
          stationId: recording.stationId,
          ...(recording.type === "thermalRaw" && {
            filtered: recording.tracks.every((track) => track.filtered),
          }),
          redacted: recording.redacted,
        };

        items.push(itemData);
      }
      this.tableItems.push(...items);
      // Now calculate chunks of days and hour groupings
      {
        const chunks = [];
        let current = chunks;
        for (const item of items) {
          if (item.kind === "dataSeparator") {
            if (item.hasOwnProperty("date")) {
              chunks.push([]);
              current = chunks[chunks.length - 1];
            }
            if (item.hasOwnProperty("hour")) {
              current.push([]);
            }
          } else {
            current[current.length - 1].push(item);
          }
        }
        // if (chunks.length === 0) {
        //   // We've reached the end of the recordings.
        //   //this.atEnd = true;
        //   // console.log("At end of recordings");
        // }
        if (
          this.recordingsChunkedByDayAndHour.length !== 0 &&
          chunks.length !== 0
        ) {
          // We need to be careful joining these here:
          const lastDay =
            this.recordingsChunkedByDayAndHour[
              this.recordingsChunkedByDayAndHour.length - 1
            ];
          const lastHour =
            lastDay[lastDay.length - 1][lastDay[lastDay.length - 1].length - 1];
          const firstDay = chunks[0];
          const firstHour = firstDay[0][0];
          if (lastHour.date === firstHour.date) {
            // We're going to push firstDay into lastDay
            if (lastHour.time.split(":")[0] === firstHour.time.split(":")[0]) {
              lastDay[lastDay.length - 1].push(...firstDay[0]);
              lastDay.push(...firstDay.slice(1));
            } else {
              lastDay.push(...firstDay);
            }
            this.recordingsChunkedByDayAndHour.push(...chunks.slice(1));
          } else {
            this.recordingsChunkedByDayAndHour.push(...chunks);
          }
        } else {
          // If lastDay/Hour is the same as previous, join them.
          this.recordingsChunkedByDayAndHour.push(...chunks);
        }
      }
      this.$emit("filtered-count", this.filteredCount);

      if (this.filteredCount == this.recordings.length) {
        if (this.recordings.length < FILTERED_MAX) {
          this.$emit("load-more");
        } else if (!this.showFiltered) {
          // not showing any recordings but tried the first 100
          // give the user a button to load more
          this.loadButton = true;
        }
      } else {
        this.loadButton = false;
      }
    },
  },
  beforeDestroy() {
    this.observer && this.observer.disconnect();
  },
  beforeUpdate() {
    this.observer && this.observer.disconnect();
  },
  updated() {
    // Setup next intersection observer to see the page has scrolled enough to load more items
    this.observer = new IntersectionObserver(this.intersectionChanged);
    // Observe intersections of cards
    const maxY = [];
    // Just observe the nth to last item, and when it comes into view, we load more, and disconnect the observer.
    const n = 3;
    for (const ref of Object.values(this.$refs)) {
      if ((ref as any[]).length !== 0 && ref != this.$refs["list-container"]) {
        if (ref[0] && ref[0].$el) {
          const bounds = ref[0].$el.getBoundingClientRect();
          maxY.push([bounds.y, ref[0].$el]);
          maxY.sort((a, b) => b[0] - a[0]);
          if (maxY.length > n) {
            maxY.pop();
          }
        }
      }
    }
    if (maxY.length) {
      const observerTrigger = maxY[maxY.length - 1][1];
      if (this.showCards) {
        let yHeight = maxY[0][0];
        if (yHeight < 0) {
          let currentHeight = this.$refs["list-container"].style.height;
          const index = currentHeight.search("px");
          if (index > 0) {
            currentHeight = Number(currentHeight.substring(0, index));
            yHeight = currentHeight + yHeight;
          }
        }
        this.$refs["list-container"].style.height = `${yHeight}px`;
      }
      this.observer && this.observer.observe(observerTrigger);
    } else {
      if (this.showCards) {
        this.$refs["list-container"].style.height = "auto";
      }
    }
  },
  methods: {
    intersectionChanged(entries: IntersectionObserverEntry[]) {
      for (const intersectionEvent of entries) {
        if (intersectionEvent.isIntersecting) {
          this.observer.unobserve(intersectionEvent.target);
          this.$emit("load-more");
        }
      }
    },
    relativeDay(itemDate) {
      itemDate = itemDate[0][0].dateObj;
      return toStringTodayYesterdayOrDate(itemDate);
    },
    hour(itemDate) {
      itemDate = itemDate.length && itemDate[0].dateObj;
      const hours = itemDate && itemDate.getHours();
      if (hours === 0) {
        return "12am";
      }
      return `${hours <= 12 ? hours : hours - 12}${hours < 12 ? "am" : "pm"}`;
    },
  },
  data() {
    return {
      recordingsChunkedByDayAndHour: [],
      tableItems: [],
      atEnd: false,
      loadedRecordingsCount: 0,
      loadButton: false,
      filteredToolTip: FILTERED_TOOLTIP,
    };
  },
  computed: {
    showFiltered: {
      set: function (val) {
        localStorage.setItem("showFiltered", val);
        this.$store.state.User.userData.showFiltered = val;
      },
      get: function () {
        return this.$store.state.User.userData.showFiltered;
      },
    },
    filteredCount() {
      return this.tableItems.filter((item) => item.filtered).length;
    },
    filteredItems() {
      if (this.showFiltered) {
        return this.tableItems;
      } else {
        return this.tableItems.filter((item) => !item.filtered);
      }
    },
    recordingsChunked() {
      if (this.showFiltered) {
        return this.recordingsChunkedByDayAndHour;
      } else {
        const filteredChunks = [];
        for (const chunk of this.recordingsChunkedByDayAndHour) {
          const hourChunks = [];
          for (const hourChunk of chunk) {
            const filteredHours = hourChunk.filter((item) => !item.filtered);
            if (filteredHours.length > 0) {
              hourChunks.push(filteredHours);
            }
          }
          if (hourChunks.length > 0) {
            filteredChunks.push(hourChunks);
          }
        }
        return filteredChunks;
      }
    },
  },
};
