






































































export default {
  name: "LoginView",
  props: {},
  data() {
    return {
      email: "",
      password: "",
      errorMessage: null,
      loggingIn: false,
    };
  },
  computed: {},
  methods: {
    async onSubmit(evt) {
      this.loggingIn = true;
      this.errorMessage = null;
      evt.preventDefault();

      const response = await this.$store.dispatch("User/LOGIN", {
        email: this.email,
        password: this.password,
      });
      if (response.success) {
        if (this.$store.getters["User/isLoggedIn"]) {
          if (this.$route.query.nextUrl) {
            await this.$router.push(this.$route.query.nextUrl);
          } else {
            this.$router.go("home");
          }
        }
      } else {
        this.errorMessage =
          response.result.messages.length && response.result.messages[0];
      }
      this.loggingIn = false;
    },
  },
};
