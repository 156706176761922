// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.spinner-wrapper[data-v-2c22d8ba] {
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}
.fa-spin[data-v-2c22d8ba] {
  color: #f5f5f5;
  margin: 0 auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/Spinner.vue"],"names":[],"mappings":";AAmBA;EACA,WAAA;EACA,aAAA;EACA,YAAA;EACA,sBAAA;EACA,uBAAA;AACA;AACA;EACA,cAAA;EACA,cAAA;AACA","sourcesContent":["<template>\n  <div class=\"spinner-wrapper\" v-show=\"fetching\">\n    <font-awesome-icon class=\"fa-spin\" icon=\"cog\" size=\"4x\" />\n  </div>\n</template>\n\n<script>\nexport default {\n  name: \"Spinner\",\n  props: {\n    fetching: {\n      type: Boolean,\n      default: true,\n    },\n  },\n};\n</script>\n\n<style scoped>\n.spinner-wrapper {\n  width: 100%;\n  display: flex;\n  height: 100%;\n  flex-direction: column;\n  justify-content: center;\n}\n.fa-spin {\n  color: #f5f5f5;\n  margin: 0 auto;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
