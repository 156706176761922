// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/hero/hero-theory.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.hero {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
  position: relative;
}

/* Mobiles */
.hero {
  height: 70px;
  width: 100%;
  object-fit: contain;
}

/* Large screens */
@media only screen and (min-width: 768px) {
.hero {
    height: 360px;
}
}
`, "",{"version":3,"sources":["webpack://./src/components/Hero.vue"],"names":[],"mappings":";AAOA;EACA,yDAAA;EACA,2BAAA;EACA,4BAAA;EACA,6BAAA;EACA,sBAAA;EACA,kBAAA;AACA;;AAEA,YAAA;AACA;EACA,YAAA;EACA,WAAA;EACA,mBAAA;AACA;;AAEA,kBAAA;AACA;AACA;IACA,aAAA;AACA;AACA","sourcesContent":["<template>\n  <div class=\"hero\" />\n</template>\n\n<script></script>\n\n<style>\n.hero {\n  background-image: url(\"../assets/hero/hero-theory.jpg\");\n  background-position: center;\n  background-repeat: no-repeat;\n  background-color: transparent;\n  background-size: cover;\n  position: relative;\n}\n\n/* Mobiles */\n.hero {\n  height: 70px;\n  width: 100%;\n  object-fit: contain;\n}\n\n/* Large screens */\n@media only screen and (min-width: 768px) {\n  .hero {\n    height: 360px;\n  }\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
