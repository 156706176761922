var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('div',{staticClass:"container",staticStyle:{"padding":"0"}},[_c('h2',[_vm._v("\n    Users\n    "),_c('help',[_vm._v("Only administrators can add new users")])],1),_vm._v(" "),_c('div',{staticClass:"description-and-button-wrapper"},[_c('p',[_vm._v("\n      Users can view recordings for the devices associated with this group.\n    ")]),_vm._v(" "),(_vm.isGroupAdmin)?_c('group-user-add',{attrs:{"group-name":_vm.groupName,"group-users":_vm.users},on:{"user-added-to-group":function($event){return _vm.$emit('user-added')}}}):_vm._e(),_vm._v(" "),(_vm.isGroupAdmin)?_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.group-user-add",modifiers:{"group-user-add":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"add-button",attrs:{"variant":"primary","title":"Add user to group","data-cy":"add_button"}},[_c('font-awesome-icon',{attrs:{"icon":"user-plus","size":"xs"}}),_vm._v(" "),_c('span',[_vm._v("Add user")])],1):_vm._e()],1),_vm._v(" "),(!_vm.groupHasUsers)?_c('div',[_c('b-card',{staticClass:"no-content-placeholder"},[_c('p',[_vm._v("This group has no users associated with it.")])])],1):_c('div',[_c('b-modal',{attrs:{"id":"group-user-remove-self","title":"Remove yourself from group","ok-title":"Remove","ok-variant":"danger"},on:{"ok":function($event){return _vm.removeGroupUser(_vm.currentUser.id)}},model:{value:(_vm.showUserRemoveSelfModal),callback:function ($$v) {_vm.showUserRemoveSelfModal=$$v},expression:"showUserRemoveSelfModal"}},[_c('p',[_vm._v("\n        Are you sure you want to remove yourself from this group? You will no\n        longer be able to view recordings from the devices in this group and\n        you will not be able to add yourself back to the group.\n      ")])]),_vm._v(" "),_c('b-table',{attrs:{"items":_vm.users,"fields":[
        {
          key: 'userName',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'admin',
          label: 'Administrator',
        },
        {
          key: 'controls',
          label: '',
          class: 'device-actions-cell',
        } ],"sort-by":"userName","striped":"","hover":"","outlined":"","responsive":"","data-cy":"users-table"},scopedSlots:_vm._u([{key:"cell(admin)",fn:function(data){return [_vm._v("\n        "+_vm._s(data.item.admin ? "Yes" : "No")+"\n      ")]}},{key:"cell(controls)",fn:function(data){return [(_vm.isGroupAdmin)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"trash-button",attrs:{"disabled":_vm.isRemovingUser,"title":"Remove user from group","variant":"light"},on:{"click":function($event){return _vm.removeGroupUserCheckIfSelf(data.item.id)}}},[_c('font-awesome-icon',{attrs:{"icon":"trash","size":"1x"}})],1):_vm._e()]}}],null,false,136060054)})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }