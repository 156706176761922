




























































































































import api from "@/api";
import Spinner from "@/components/Spinner.vue";
import GroupAdd from "@/components/Groups/GroupAdd.vue";

import { LatLng, latLng, latLngBounds } from "leaflet";
import MapWithPoints from "@/components/MapWithPoints.vue";
import { mapState } from "vuex";
import { ApiGroupResponse, ApiGroupSettings } from "@typedefs/api/group";
import { ApiLoggedInUserResponse } from "@typedefs/api/user";
import { ApiDeviceResponse } from "@typedefs/api/device";

interface GroupsForLocation {
  location: LatLng;
  name: string;
}

interface GroupInfo {
  devices: ApiDeviceResponse[];
  groupName: string;
  settings?: ApiGroupSettings;
  lastThermalRecordingTime?: Date;
  lastAudioRecordingTime?: Date;
}

interface GroupsViewData {
  groups: ApiGroupResponse[];
  isLoading: boolean;
  locationsLoading: boolean;
  showGroupsWithNoDevices: boolean;
  showGroupsWithNoRecordings: boolean;
  locations: Record<string, GroupsForLocation>;
  requestController: AbortController;
}

const NZ_BOUNDS = latLngBounds([
  latLng(-33.6233075, 176.6248297),
  latLng(-47.5254414, 164.9880683),
]);

// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
const isInNZ = (location: LatLng): boolean => {
  return NZ_BOUNDS.contains(location);
};

export default {
  name: "GroupsView",
  components: {
    MapWithPoints,
    Spinner,
    GroupAdd,
  },
  data(): GroupsViewData {
    return {
      groups: [],
      isLoading: false,
      showGroupsWithNoDevices: false,
      showGroupsWithNoRecordings: false,
      locationsLoading: false,
      locations: {},
      requestController: null,
    };
  },
  computed: {
    ...mapState({
      currentUser: (state): ApiLoggedInUserResponse =>
        (state as any).User.userData,
    }),
    hasGroups(): boolean {
      return this.groups.length !== 0;
    },
    groupsByLocation(): GroupsForLocation[] {
      return Object.values(this.locations);
    },
    filteredGroups(): GroupInfo[] {
      return this.groups.filter((group) => {
        let pass = true;
        if (!this.showGroupsWithNoDevices) {
          pass = group.devices.length !== 0;
        }
        if (!this.showGroupsWithNoRecordings) {
          pass =
            !!group.lastAudioRecordingTime || !!group.lastThermalRecordingTime;
        }
        return pass;
      });
    },
    privacyGroups(): GroupInfo[] {
      return this.groups.filter(
        (group: ApiGroupResponse) => group.settings?.filterHuman
      );
    },
    orderedGroups(): ApiGroupResponse[] {
      return [...this.filteredGroups].sort(
        (a: ApiGroupResponse, b: ApiGroupResponse) => {
          const aDateThermal =
            a.lastThermalRecordingTime && new Date(a.lastThermalRecordingTime);
          const aDateAudio =
            a.lastAudioRecordingTime && new Date(a.lastAudioRecordingTime);
          const bDateThermal =
            b.lastThermalRecordingTime && new Date(b.lastThermalRecordingTime);
          const bDateAudio =
            b.lastAudioRecordingTime && new Date(b.lastAudioRecordingTime);
          let aDate;
          if (aDateAudio && aDateThermal) {
            aDate = aDateAudio > aDateThermal ? aDateAudio : aDateThermal;
          } else {
            aDate = aDateThermal || aDateAudio;
          }
          let bDate;
          if (bDateAudio && bDateThermal) {
            bDate = bDateAudio > bDateThermal ? bDateAudio : bDateThermal;
          } else {
            bDate = bDateThermal || bDateAudio;
          }
          if (aDate && bDate) {
            return bDate.getTime() - aDate.getTime();
          } else if (aDate) {
            return -1;
          } else if (bDate) {
            return 1;
          }
          return a.groupName.localeCompare(b.groupName);
        }
      );
    },
  },
  created: function () {
    this.fetchGroups();
  },
  methods: {
    async fetchGroups() {
      this.isLoading = true;
      this.locationsLoading = true;
      {
        // TODO(jon): Error handling.

        const groups: Record<number, GroupInfo> = {};
        try {
          const [userGroups, userDevices] = await Promise.all([
            // NOTE - We only need to get groups because there can be groups with
            //  no devices - otherwise all groups would be listed in devices
            api.groups.getGroups(),
            api.device.getDevices(), // This should become stations
          ]);
          {
            if (userGroups.success) {
              const { result } = userGroups;
              for (const {
                id,
                groupName,
                settings,
                lastThermalRecordingTime,
                lastAudioRecordingTime,
              } of result.groups) {
                groups[id] = {
                  devices: [],
                  groupName,
                  settings,
                };
                if (lastAudioRecordingTime) {
                  groups[id].lastAudioRecordingTime = new Date(
                    lastAudioRecordingTime
                  );
                }
                if (lastThermalRecordingTime) {
                  groups[id].lastThermalRecordingTime = new Date(
                    lastThermalRecordingTime
                  );
                }
              }
            } else {
              // FIXME?
            }
          }
          {
            if (userDevices.success) {
              const { result } = userDevices;
              const locations = {};
              if (!result.devices.length) {
                this.showGroupsWithNoDevices = true;
              }
              for (const device of result.devices) {
                if (device.location) {
                  // TODO - Expand group bubble to encompass all devices
                  const location = latLng(
                    device.location.lat,
                    device.location.lng
                  );
                  if (isInNZ(location)) {
                    if (!locations.hasOwnProperty(location.toString())) {
                      locations[location.toString()] = {
                        location,
                        group: device.groupName,
                      };
                    }
                    const loc = locations[location.toString()];
                    loc.group = device.groupName;
                    loc.name = device.deviceName;
                  }
                }

                const { groupName, groupId } = device;
                groups[groupId] = groups[groupId] || {
                  devices: [],
                  groupName,
                };
                groups[groupId].devices.push(device);
                // Now we should be able to show the groups for those devices.
              }
              this.locations = locations;
            } else {
              // FIXME?
            }
            this.locationsLoading = false;
          }
        } catch (e) {
          // ...
        }
        this.groups = Object.values(groups).sort((a, b) =>
          a.groupName.localeCompare(b.groupName)
        );
      }
      this.isLoading = false;
    },
  },
};
