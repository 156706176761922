























































import SelectDevice from "./SelectDevice.vue";
import SelectTags from "./SelectTags.vue";
import SelectDuration from "./SelectDuration.vue";
import SelectRecordingType from "./SelectRecordingType.vue";
import SelectDateRange from "./SelectDateRange.vue";

export default {
  name: "QueryRecordings",
  components: {
    SelectDateRange,
    SelectDevice,
    SelectTags,
    SelectDuration,
    SelectRecordingType,
  },
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
    isCollapsed: {
      type: Boolean,
      required: true,
    },
    onlyRecordingType: {
      type: String,
      required: false,
    },
    simpleOnly: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      lastQuery: null,
      query: {},
      rawAnimals: [],
      advanced: false,
      selectedDevices: [],
      selectedGroups: [],
      selectedStations: [],
      dates: {},
      dateDescription: "",
      duration: {},
      recordingType: "",
      tagData: {},
    };
  },
  computed: {
    isAudio: function () {
      return this.recordingType === "audio";
    },
    groups: function () {
      return this.$store.state.Groups;
    },
  },
  created() {
    this.resetToDefaultQuery();

    if (Object.keys(this.$route.query).length !== 0) {
      this.deserialiseQuery(this.$route.query);
    }
  },
  mounted() {
    this.onMountOrSubmit("mounted");
  },
  methods: {
    resetToDefaultQuery() {
      this.selectedDevices = [];
      this.selectedGroups = [];
      this.selectedStations = [];
      this.dates = {
        days: 7,
      };
      this.duration = {};
      this.recordingType = this.$store.state.User.recordingTypePref || "both";
      this.tagData = {
        tagMode: "any",
      };
    },
    setAdvancedInitialState() {
      if (this.simpleOnly) {
        this.advanced = false;
      } else {
        // If there was an advanced query, start with the advanced toggle area open.
        this.advanced =
          (this.tagData && this.tagData.tagMode !== "any") ||
          this.duration.hasOwnProperty("maxS") ||
          this.duration.hasOwnProperty("minS");
      }
    },
    deserialiseQuery(routeQuery) {
      setOnlyIfExists("tagMode", routeQuery, this.tagData);

      setOnlyIfExists("minS", routeQuery, this.duration);
      setOnlyIfExists("maxS", routeQuery, this.duration);

      if (routeQuery.hasOwnProperty("tag")) {
        this.tagData.tags = makeArray(routeQuery.tag);
      }

      if (routeQuery.hasOwnProperty("type")) {
        this.recordingType = routeQuery.type;
      }

      if (routeQuery.hasOwnProperty("exclusive")) {
        this.tagData.exclusive = routeQuery.exclusive;
      }

      this.dates = {
        days: Number(routeQuery.days),
        to: routeQuery.to,
        from: routeQuery.from,
      };

      if (routeQuery.hasOwnProperty("group")) {
        this.selectedGroups = makeArray(routeQuery.group).map((item) =>
          Number(item)
        );
      }
      if (routeQuery.hasOwnProperty("device")) {
        this.selectedDevices = makeArray(routeQuery.device).map((item) =>
          Number(item)
        );
      }
      if (routeQuery.hasOwnProperty("station")) {
        this.selectedStations = makeArray(routeQuery.station).map((item) =>
          Number(item)
        );
      }

      this.setAdvancedInitialState();
    },
    serialiseQuery() {
      const query = {
        tagMode: this.tagData.tagMode,
        exclusive: this.tagData.exclusive,
        tag: this.tagData.tags,
        minS: this.duration.minS,
        maxS: this.duration.maxS,
        days: this.dates.days,
        from: this.dates.from,
        to: this.dates.to,
        type: this.recordingType,
        device: this.selectedDevices,
        group: this.selectedGroups,
        station: this.selectedStations,
      };

      for (const [key, value] of Object.entries(query)) {
        if (typeof value === "string" && Number(value).toString() === value) {
          query[key] = Number(value);
        }
      }
      return query;
    },

    submit: function () {
      if (!this.onlyRecordingType) {
        this.$store.commit("User/updateRecordingTypePref", this.recordingType);
      }

      // Every time we submit a new query, we need to clear the offset param, as if we are on page 2+ of the results,
      // we can end up with an offset that is greater than the number of results in the new query.
      this.onMountOrSubmit();
    },

    onMountOrSubmit: function (event = "submit") {
      this.lastQuery = this.serialiseQuery();
      this.$emit("description", this.makeSearchDescription());
      this.toggleSearchPanel();
      this.$emit(event, { ...this.lastQuery, offset: 0 });
    },
    toggleAdvancedSearch: function () {
      this.advanced = !this.advanced;
    },
    toggleSearchPanel() {
      this.$emit("toggled-search-panel");
    },
    devicesDescription() {
      const numDevices = this.selectedDevices.length;
      const numGroups = this.selectedGroups.length;
      const numStations = this.selectedStations.length;
      const total = numDevices + numGroups + numStations;

      const multipleSuffix = total > 1 ? "s" : "";

      if (total === 0) {
        return "All devices";
      } else if (numGroups && !numDevices && !numStations) {
        return `${total} group${multipleSuffix}`;
      } else if (numStations && !numDevices && !numGroups) {
        return `${total} station${multipleSuffix}`;
      } else if (numDevices && !numStations && !numGroups) {
        return `${total} device${multipleSuffix}`;
      } else {
        const items = [];
        if (numGroups) {
          items.push("groups");
        }
        if (numDevices) {
          items.push("devices");
        }
        if (numStations) {
          items.push("stations");
        }
        return `${total} ${items.join(" and ")}`;
      }
    },
    makeSearchDescription() {
      // Get the current search query, not the live updated one.
      const devices = this.devicesDescription();
      const tagsText = this.tagData.description || "";
      const timespan = this.dates.description || "";
      const durationStr = this.duration.description || "";

      const recordings =
        this.recordingType === "both" ? "audio and video" : this.recordingType;

      return (
        `<strong>${devices}</strong>, <strong>${recordings} recordings</strong> and <strong>${tagsText}</strong> ` +
        `${timespan}${durationStr}`
      );
    },

    updateDeviceSelection(eventData) {
      if (eventData.hasOwnProperty("devices")) {
        this.selectedDevices = eventData.devices;
      }
      if (eventData.hasOwnProperty("groups")) {
        this.selectedGroups = eventData.groups;
      }
      if (eventData.hasOwnProperty("stations")) {
        this.selectedStations = eventData.stations;
      }
    },
  },
};

function setOnlyIfExists(itemName, source, destination) {
  if (source.hasOwnProperty(itemName)) {
    destination[itemName] = source[itemName];
  }
}

function makeArray(value) {
  if (Array.isArray(value)) {
    return value;
  } else {
    return value ? [value] : [];
  }
}
