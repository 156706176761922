

















export default {
  name: "GroupLink",
  props: {
    groupName: {
      type: String,
      required: true,
    },
    context: {
      type: String,
    },
    useLink: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    shouldLink() {
      if (this.useLink === false) {
        return this.useLink;
      }
      if (
        this.$route.name === "group" &&
        this.$route.params.groupName &&
        this.$route.params.groupName === this.groupName
      ) {
        return false;
      }
      return this.useLink;
    },
    params() {
      if (typeof this.context !== "undefined" && this.context.trim() !== "") {
        return {
          groupName: this.groupName,
          tabName: this.context,
        };
      }
      return {
        groupName: this.groupName,
      };
    },
  },
};
