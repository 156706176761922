













































































import { mapState } from "vuex";
import { isViewingAsOtherUser } from "@/components/NavBar.vue";
import { shouldViewAsSuperUser } from "@/utils";
import api from "@/api";

export default {
  name: "StationReferencePhotosTab",
  props: {
    station: {
      required: true,
    },
    group: {
      required: true,
    },
  },
  data() {
    return {
      images: [],
      referenceImageKeys: [],
      selectedUpload: null,
      modalImage: null,
      showModal: false,
    };
  },
  async mounted() {
    // Load any existing station images.
    if (this.referenceImageKeys.length === 0) {
      this.referenceImageKeys =
        (this.station.settings && this.station.settings.referenceImages) || [];
      for (const key of this.referenceImageKeys) {
        const imageItem = {
          loading: true,
          key,
          image: null,
        };
        this.images.push(imageItem);
        api.station.getReferenceImage(this.station.id, key).then((image) => {
          imageItem.image = window.URL.createObjectURL(image.result as Blob);
          imageItem.loading = false;
        });
      }
    }
  },
  methods: {
    openImageInModal(image: string) {
      this.showModal = true;
      this.modalImage = image;
    },
    async deleteImage(fileKey: string) {
      this.images = this.images.filter(({ key }) => key !== fileKey);
      this.referenceImageKeys = this.referenceImageKeys.filter(
        (key) => key !== fileKey
      );
      await api.station.deleteReferenceImage(this.station.id, fileKey);
    },
    async uploadSelectedFiles() {
      // First, resize images using canvas.
      // Then, append them to a FormData, then upload each form data as a separate API request.
      //for (const file of this.selectedUploads as File[]) {
      const file = this.selectedUpload as File;
      const reader = new FileReader();
      const image = document.createElement("img");
      const readerEnd = new Promise<void>((resolve) => {
        reader.onloadend = (data) => {
          image.src = data.target.result as string;
          resolve();
        };
      });
      await reader.readAsDataURL(file);
      await readerEnd;
      await new Promise((resolve) => {
        image.onload = resolve;
      });
      const ratio = image.width / image.height;
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      if (ratio > 1) {
        // landscape
        canvas.width = 1600;
        canvas.height = Math.floor(canvas.width / ratio);
      } else {
        // portrait
        canvas.height = 1600;
        canvas.width = Math.floor(canvas.height / ratio);
      }
      // This will scale up some smaller images, but we're expecting that most of these come from camera phones
      // and are reasonably high res.
      context.drawImage(image, 0, 0, canvas.width, canvas.height);

      const blob: Blob = await new Promise((resolve) =>
        canvas.toBlob(resolve, "image/jpeg", 80)
      );
      const response = await api.station.uploadReferenceImage(
        this.station.id,
        blob
      );
      if (response.success) {
        this.referenceImageKeys.push(response.result.fileKey);
        this.images.push({
          key: response.result.fileKey,
          image: window.URL.createObjectURL(blob),
        });
      }
      //}
    },
  },
  computed: {
    ...mapState({
      currentUser: (state) => (state as any).User.userData,
    }),
    userIsSuperUserAndViewingAsSuperUser() {
      return (
        this.currentUser.globalPermission === "write" &&
        (isViewingAsOtherUser() || shouldViewAsSuperUser())
      );
    },
    userIsMemberOfGroup(): boolean {
      return this.userIsSuperUserAndViewingAsSuperUser || !!this.group;
    },
    userIsGroupAdmin() {
      return (
        this.userIsSuperUserAndViewingAsSuperUser ||
        (this.group && this.group.admin)
      );
    },
  },
};
