



































































































import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  PropType,
  ref,
  watch,
} from "@vue/composition-api";

export type Option = {
  label: string;
  display?: string;
  children?: Option[];
};

export default defineComponent({
  name: "LayeredDropdown",
  props: {
    options: {
      type: Object as PropType<Option>,
      required: true,
    },
    value: {
      type: [Array, String] as PropType<string | string[]>,
      default: () => [],
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    placeholder: {
      type: String as PropType<string>,
      default: "Search",
    },
  },
  setup(props, { emit }) {
    // Elements
    const optionsList = ref<HTMLDivElement>(null);
    const optionsContainerRef = ref<HTMLDivElement>(null);
    const inputRef = ref(null);

    // Options Reactive Variables
    const currPath = ref<string[]>([]);
    const optionsMap = ref<
      Map<string, { display: string; label: string; path: string[] }>
    >(new Map([["all", { display: "all", label: "all", path: ["all"] }]]));
    const showOptions = ref(false);
    const selectedOptions = computed({
      get() {
        return props.value;
      },
      set(value) {
        showOptions.value = typeof selectedOptions.value !== "string";
        emit("input", value);
      },
    }); // Search
    const searchTerm = ref("");

    // Breadth-first search for options matching the search term and "label" property, and "children" as nodes.
    const searchOptions = (options: Option[]) => {
      return options.reduce((acc, option) => {
        if (
          option.label.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
          (option.display &&
            option.display
              .toLowerCase()
              .includes(searchTerm.value.toLowerCase()))
        ) {
          acc.push(option);
        }
        if (option.children) {
          acc = acc.concat(searchOptions(option.children));
        }
        return acc;
      }, []);
    };

    const addSelectedOption = (event: Event, option: Option) => {
      event.preventDefault();
      if (typeof selectedOptions.value === "string") {
        selectedOptions.value = option.label;
        return;
      }
      if (Array.isArray(selectedOptions.value)) {
        if (selectedOptions.value.find((o) => o === option.label)) {
          return;
        }
        selectedOptions.value = [...selectedOptions.value, option.label];
      }
    };

    const addSearchTermOnSubmit = (event: KeyboardEvent | MouseEvent) => {
      if (event instanceof KeyboardEvent) {
        if (event.key === "Enter") {
          if (searchTerm.value === "") {
            return;
          }
          const option = optionsMap.value.get(searchTerm.value.toLowerCase());
          if (option) {
            addSelectedOption(event, option);
            searchTerm.value = "";
          } else if (displayedOptions.value.length === 1) {
            addSelectedOption(event, displayedOptions.value[0]);
            searchTerm.value = "";
          }
        } else if (event.key === "Tab") {
          showOptions.value = !showOptions.value;
        }
      }
    };

    const removeSelectedOption = (option: string) => {
      if (typeof selectedOptions.value === "string") {
        selectedOptions.value = "";
      } else if (
        typeof selectedOptions.value === "object" &&
        Array.isArray(selectedOptions.value)
      ) {
        selectedOptions.value = selectedOptions.value.filter(
          (o) => o !== option
        );
      }
    };

    const setToPath = (label: string) => {
      currPath.value = optionsMap.value.get(label.toLowerCase())?.path ?? [
        "all",
      ];
    };

    const createOptionsPaths = (root: Option) => {
      const navigate = (node: Option, path: string[]) => {
        optionsMap.value.set(node.label, {
          display: node.display ?? node.label,
          label: node.label,
          path: [...path, node.label],
        });
        if (node.children) {
          node.children.forEach((child) => {
            navigate(child, [...path, node.label]);
          });
        }
      };
      navigate(root, []);
    };

    watch(
      () => props.options,
      () => {
        createOptionsPaths(props.options);
        setToPath("all");
      },
      { immediate: true }
    );
    const displayedOptions = computed(() => {
      const searching = searchTerm.value === "";
      if (!searching) {
        return searchOptions(props.options.children);
      } else {
        return currPath.value.reduce((acc, path) => {
          if (path === "all") {
            return acc;
          }
          const children: Option = acc.children.find(
            ({ label }) => label === path
          );

          return children;
        }, props.options).children;
      }
    });

    watch(searchTerm, () => {
      if (searchTerm.value === "") {
        if (currPath.value[0] === "search") {
          setToPath("all");
        }
        return;
      }
      currPath.value = ["search"];
    });

    watch(inputRef, () => {
      if (showOptions.value && inputRef.value) {
        inputRef.value.focus();
      }
    });
    const id = ref(Math.random().toString(36).substring(2, 15)); // Use Vue's ref for reactivity

    onMounted((t) => {
      document.addEventListener("keydown", (e) => {
        if (e.key === "Escape") {
          showOptions.value = false;
          searchTerm.value = "";
          setToPath("all");
        }
      });

      document.addEventListener("click", (e) => {
        const target = e.target as HTMLElement;
        const currentDropdown = optionsContainerRef.value; // Note: Using id.value as id is a ref

        // Existing conditions
        const isWithinDropdown = currentDropdown?.contains(target) ?? false;
        const isOptionList = target.contains(optionsList.value);
        const isSelectedOptionString =
          typeof selectedOptions.value === "string";

        const buttonId = `options-button-${id.value}`;
        const isSwitchedParent = target.closest("button")?.id === buttonId;

        if (!isWithinDropdown || (isOptionList && isSelectedOptionString)) {
          if (isSwitchedParent) {
            showOptions.value = true;
            searchTerm.value = "";
          } else {
            showOptions.value = false;
            setToPath("all");
            searchTerm.value = "";
          }
        } else {
          showOptions.value = !props.disabled;
        }
      });
    });

    return {
      id,
      optionsMap,
      optionsContainerRef,
      optionsList,
      currPath,
      displayedOptions,
      showOptions,
      searchTerm,
      inputRef,
      addSelectedOption,
      removeSelectedOption,
      setToPath,
      addSearchTermOnSubmit,
    };
  },
});
