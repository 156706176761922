// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#username[data-v-be6df88a] {
  vertical-align: baseline;
}
#username span[data-v-be6df88a] {
  display: inline-block;
  line-height: 100%;
  vertical-align: middle;
}
`, "",{"version":3,"sources":["webpack://./src/components/HomeUser.vue"],"names":[],"mappings":";AA4DA;EACA,wBAAA;AACA;AACA;EACA,qBAAA;EACA,iBAAA;EACA,sBAAA;AACA","sourcesContent":["<template>\n  <div>\n    <b-modal\n      v-model=\"changeUserName\"\n      title=\"Change your display name\"\n      @ok=\"alterUserName\"\n    >\n      <b-form-group label=\"New display name\">\n        <b-input\n          type=\"text\"\n          v-model=\"newUserName\"\n          placeholder=\"New display name\"\n        />\n      </b-form-group>\n    </b-modal>\n    <h3>Your details</h3>\n    <dl>\n      <dt>Your display name</dt>\n      <dd id=\"username\">\n        <span>{{ userName }}</span>\n        <b-btn @click=\"updateUserName\" size=\"sm\" variant=\"transparent\"\n          ><font-awesome-icon icon=\"pencil-alt\" size=\"xs\" color=\"#666\"\n        /></b-btn>\n      </dd>\n      <dt>Email</dt>\n      <dd id=\"email\">{{ email }}</dd>\n    </dl>\n  </div>\n</template>\n\n<script>\nimport { mapState } from \"vuex\";\n\nexport default {\n  name: \"HomeUser\",\n  data() {\n    return {\n      changeUserName: false,\n      newUserName: this.userName,\n    };\n  },\n  methods: {\n    updateUserName() {\n      this.changeUserName = true;\n      this.newUserName = this.userName;\n    },\n    async alterUserName() {\n      await this.$store.dispatch(\"User/UPDATE\", { userName: this.newUserName });\n      this.newUserName = \"\";\n    },\n  },\n  computed: mapState({\n    user: (state) => state.User,\n    userName: (state) => state.User.userData.userName,\n    email: (state) => state.User.userData.email,\n  }),\n};\n</script>\n\n<style scoped>\n#username {\n  vertical-align: baseline;\n}\n#username span {\n  display: inline-block;\n  line-height: 100%;\n  vertical-align: middle;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
