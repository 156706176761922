






















































import { defineComponent, PropType, watch } from "@vue/composition-api";
import { useState } from "@/utils";

export default defineComponent({
  name: "LabelButtonGroup",
  props: {
    labels: {
      type: Array as PropType<{ label: string; pinned: boolean }[]>,
      required: true,
    },
    cols: {
      type: Number,
      default: 3,
    },
    addTagToSelectedTrack: {
      type: Function as PropType<(what: string) => Promise<void>>,
      required: true,
    },
    deleteTagFromSelectedTrack: {
      type: Function as PropType<() => Promise<void>>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selectedLabel: {
      type: String,
      default: "",
    },
    togglePinTag: {
      type: Function as PropType<(label: string) => void>,
      required: true,
    },
  },
  setup(props) {
    // create 2d array of labels where each row has props.cols elements
    const createGridValues = (labels: { label: string; pinned: boolean }[]) =>
      labels.reduce((acc, label, index) => {
        const rowIndex = Math.floor(index / props.cols);
        const colIndex = index % props.cols;
        if (!acc[rowIndex]) {
          acc[rowIndex] = [];
        }
        acc[rowIndex][colIndex] = label;
        return acc;
      }, [] as { label: string; pinned: boolean }[][]);
    const [gridValues, setGridValues] = useState(
      createGridValues(props.labels)
    );
    const [hoveredPinned, setHoveredPinned] = useState(null);

    watch(
      () => props.labels,
      (newLabels) => {
        setGridValues(createGridValues(newLabels));
      }
    );
    return {
      gridValues,
      hoveredPinned,
      setHoveredPinned,
    };
  },
});
