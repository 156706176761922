var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',_vm._l((_vm.gridValues),function(row,rowIndex){return _c('b-row',{key:rowIndex,staticClass:"py-1"},_vm._l((row),function(ref,colIndex){
var label = ref.label;
var pinned = ref.pinned;
return _c('b-col',{key:colIndex,staticClass:"px-1"},[(pinned)?_c('div',{attrs:{"role":"button"},on:{"mouseover":function($event){return _vm.setHoveredPinned(label)},"mouseout":function($event){return _vm.setHoveredPinned(null)},"click":function () {
            _vm.togglePinTag(label);
            _vm.setHoveredPinned(null);
          }}},[(label === _vm.hoveredPinned)?_c('font-awesome-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"pinned-button pinned-button-cross",attrs:{"icon":"times","size":"1x"}}):_c('font-awesome-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"pinned-button",attrs:{"icon":"thumbtack","size":"1x"}})],1):_vm._e(),_vm._v(" "),_c('b-button',{staticClass:"label-button w-100 h-100",class:{
          highlight: _vm.selectedLabel.toLowerCase() === label.toLowerCase(),
        },attrs:{"variant":"outline","disabled":_vm.disabled},on:{"click":function($event){_vm.selectedLabel.toLowerCase() === label.toLowerCase()
            ? _vm.deleteTagFromSelectedTrack()
            : _vm.addTagToSelectedTrack(label)}}},[_vm._v("\n        "+_vm._s(label)+"\n      ")])],1)}),1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }