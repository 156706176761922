































































import { mapState } from "vuex";
import api from "@/api";
import HomeGroups from "@/components/HomeGroups.vue";
import HomeUser from "@/components/HomeUser.vue";
import Hero from "@/components/Hero.vue";
import Spinner from "@/components/Spinner.vue";

export default {
  name: "HomeView",
  components: {
    Hero,
    HomeGroups,
    HomeUser,
    Spinner,
  },
  data() {
    return {
      hasLoaded: false,
      groups: [],
    };
  },
  computed: {
    ...mapState({
      email: (state) => (state as any).User.userData.email,
      userName: (state) => (state as any).User.userData.userName,
    }),
    hasGroups() {
      return this.groups.length !== 0;
    },
  },
  async created() {
    await this.fetchGroups();
  },
  methods: {
    async fetchGroups() {
      this.hasLoaded = false;
      const response = await api.groups.getGroups();
      if (response.success) {
        this.groups = response.result.groups;
      }
      this.hasLoaded = true;
    },
  },
};
