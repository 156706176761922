


























import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "SelectDate",
  props: {
    title: {
      type: String,
      default: "Select date",
    },
    value: {
      type: String,
      required: true,
    },
    beforeDateTime: {
      type: String,
      default: "",
    },
    afterDateTime: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      time: "",
      date: "",
    };
  },
  methods: {
    onCreateParseDate(newDate) {
      this.date = parseDate(newDate);
      const parsedTime = parseTime(newDate);

      if (parsedTime.length > 0) {
        this.time = parsedTime;
      } else {
        this.time = "12:00";
      }
    },
  },
  created: function () {
    this.onCreateParseDate(this.value);
  },
  computed: {
    minDate: function () {
      return parseDate(this.afterDateTime);
    },
    maxDate: function () {
      return parseDate(this.beforeDateTime);
    },
    minTime: function () {
      if (this.date === this.minDate) {
        return parseTime(this.afterDateTime);
      }
      return "";
    },
    maxTime: function () {
      if (this.date === this.maxDate) {
        return parseTime(this.beforeDateTime);
      }
      return "";
    },
    timeDate: function () {
      if (this.date.length > 0 && this.time.length > 0) {
        return this.date + " " + this.time + ":00";
      }
      return "";
    },
  },
  watch: {
    timeDate: function (value) {
      this.$emit("input", value);
    },
  },
});

function parseDate(fullDateTime) {
  if (fullDateTime) {
    var parts = fullDateTime.split(" ");
    if (parts.length == 2 && parts[1].length > 4) {
      return parts[0];
    }
  }
  return "";
}

function parseTime(fullDateTime) {
  if (fullDateTime) {
    var parts = fullDateTime.split(" ");
    if (parts.length == 2 && parts[1].length > 4) {
      return parts[1].substring(0, 5);
    }
  }
  return "";
}
