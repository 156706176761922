var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form',[_c('b-form-group',[(
          _vm.recording.additionalMetadata &&
          'user-entered' in _vm.recording.additionalMetadata
        )?_c('div',[_c('h3',[_vm._v("Properties")]),_vm._v(" "),_c('div',_vm._l((_vm.recording.additionalMetadata[
              'user-entered'
            ]),function(value,key){return _c('p',{key:key,staticClass:"user-prop"},[_c('strong',[_vm._v(_vm._s(key)+":")]),_vm._v(" "+_vm._s(value)+"\n          ")])}),0)]):_vm._e(),_vm._v(" "),(_vm.isSuperUserAndViewingAsSuperUser)?_c('div',{staticClass:"simple-accordion-wrapper"},[_c('h5',{staticClass:"simple-accordion-header",attrs:{"id":"technical-details"},on:{"click":function($event){_vm.display = !_vm.display}}},[_vm._v("\n          Technical details  \n          "),(!_vm.display)?_c('span',{staticClass:"pointer",attrs:{"title":"Show details"}},[_c('font-awesome-icon',{staticClass:"fa-1x",attrs:{"icon":"angle-down"}})],1):_vm._e(),_vm._v(" "),(_vm.display)?_c('span',{staticClass:"pointer",attrs:{"title":"Hide details"}},[_c('font-awesome-icon',{staticClass:"fa-1x",attrs:{"icon":"angle-up"}})],1):_vm._e()]),_vm._v(" "),(_vm.display)?_c('div',_vm._l((_vm.properties),function(prop){return _c('div',{key:prop.key},[(_vm.recording.batteryLevel && prop.key === 'batteryLevel')?_c('p',{staticClass:"prop"},[_c('strong',[_vm._v("Battery Level: ")]),_c('BatteryLevel',{attrs:{"battery-level":_vm.recording.batteryLevel}})],1):(_vm.recording.location && prop.key === 'location')?_c('p',{staticClass:"prop"},[_c('strong',[_vm._v("Location: ")]),_vm._v(_vm._s(_vm.parseLocation)+"\n            ")]):(
                _vm.recording.additionalMetadata &&
                prop.key === 'additionalMetadata'
              )?_c('div',_vm._l((_vm.recording.additionalMetadata),function(value,key){return _c('div',{key:key},[(key != 'tracks' && key != 'user-entered')?_c('p',{staticClass:"prop"},[_c('strong',[_vm._v(_vm._s(key)+":")]),_vm._v(" "+_vm._s(value)+"\n                ")]):_vm._e()])}),0):(_vm.recording[prop.key] != null)?_c('p',{staticClass:"prop"},[_c('strong',[_vm._v(_vm._s(prop.title)+":")]),_vm._v(" "+_vm._s(_vm.recording[prop.key])+"\n            ")]):_vm._e()])}),0):_vm._e()]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }