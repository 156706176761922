

































































































import GroupUserAdd from "./GroupUserAdd.vue";
import Help from "@/components/Help.vue";
import api from "@/api";
import { mapState } from "vuex";
import { shouldViewAsSuperUser } from "@/utils";

// TODO(jon): Add device users - users who can't see the group, but who can see a particular device in the group.
//  Then we need a way for those users to see their device.

export default {
  name: "UsersTab",
  props: {
    isGroupAdmin: { type: Boolean, default: false },
    users: { type: Array, required: true },
    loading: { type: Boolean, default: false },
    groupName: { type: String, required: true },
  },
  components: {
    GroupUserAdd,
    Help,
  },
  data() {
    return {
      isRemovingUser: false,
      showUserRemoveSelfModal: false,
    };
  },
  computed: {
    ...mapState({
      currentUser: (state) => (state as any).User.userData,
    }),
    groupHasUsers() {
      return this.users.length !== 0;
    },
    isSuperUserAndViewingAsSuperUser(): boolean {
      return (
        this.$store.state.User.userData.isSuperUser && shouldViewAsSuperUser()
      );
    },
  },
  methods: {
    async removeGroupUser(userId: number) {
      this.isRemovingUser = true;
      {
        await api.groups.removeGroupUser(this.groupName, userId);
        // Mutate our local users object, we don't need to fetch it again.
        this.$emit("user-removed", userId);
      }
      this.isRemovingUser = false;
    },
    async removeGroupUserCheckIfSelf(userId: number) {
      if (
        !this.isSuperUserAndViewingAsSuperUser &&
        userId === this.currentUser.id
      ) {
        this.showUserRemoveSelfModal = true;
      } else {
        await this.removeGroupUser(userId);
      }
    },
  },
};
