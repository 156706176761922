








export default {
  name: "SelectTabListItem",
  props: {
    title: {
      type: String,
      required: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    select() {
      this.$emit("selected");
    },
  },
};
