// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.label[data-v-45e0c34c] {
  vertical-align: middle;
}
`, "",{"version":3,"sources":["webpack://./src/components/BatteryLevel.vue"],"names":[],"mappings":";AAuCA;EACA,sBAAA;AACA","sourcesContent":["<template>\n  <span>\n    <font-awesome-icon :icon=\"icon\" />\n    <span class=\"label\">{{ batteryLevel }}%</span>\n  </span>\n</template>\n\n<script>\nimport { defineComponent } from \"@vue/composition-api\";\n\nexport default defineComponent({\n  name: \"BatteryLevel\",\n  props: {\n    batteryLevel: {\n      type: Number,\n      default: null,\n    },\n  },\n  computed: {\n    icon: function () {\n      if (this.batteryLevel < 10) {\n        return \"battery-empty\";\n      } else if (this.batteryLevel < 37.5) {\n        return \"battery-quarter\";\n      } else if (this.batteryLevel < 62.5) {\n        return \"battery-half\";\n      } else if (this.batteryLevel < 90) {\n        return \"battery-three-quarters\";\n      } else if (this.batteryLevel <= 100) {\n        return \"battery-full\";\n      } else {\n        return null;\n      }\n    },\n  },\n});\n</script>\n\n<style scoped>\n.label {\n  vertical-align: middle;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
