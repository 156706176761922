


























import SelectTabListItem from "@/components/SelectTabListItem.vue";
export default {
  name: "TabListItem",
  components: { SelectTabListItem },
  props: {
    title: {
      type: String,
      required: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    showTabs: {
      type: Boolean,
      default: true,
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    select() {
      this.$parent.$emit("selected");
      this.$parent.$emit("input", this.index);
    },
  },

  computed: {
    showTab() {
      return this.show ? "" : "d-none";
    },
  },
};
