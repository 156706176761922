





























export default {
  name: "DeviceLink",
  props: {
    groupName: {
      type: String,
      required: true,
    },
    type: {
      required: true,
    },
    deviceName: {
      type: String,
      required: true,
    },
    context: {
      type: String,
    },
    useLink: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    shouldLink() {
      if (this.useLink === false) {
        return this.useLink;
      }
      if (
        this.$route.name === "device" &&
        this.$route.params.groupName &&
        this.$route.params.groupName === this.groupName &&
        this.$route.params.deviceName &&
        this.$route.params.deviceName === this.deviceName
      ) {
        return false;
      }
      return this.useLink;
    },
    params() {
      if (typeof this.context !== "undefined" && this.context.trim() !== "") {
        return {
          groupName: this.groupName,
          deviceName: this.deviceName,
          tabName: this.context,
        };
      }
      return {
        groupName: this.groupName,
        deviceName: this.deviceName,
      };
    },
  },
};
