


















export default {
  name: "StationLink",
  props: {
    groupName: {
      type: String,
      required: true,
    },
    stationName: {
      type: String,
    },
    stationId: {
      type: Number,
    },
    context: {
      type: String,
    },
    useLink: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    shouldLink() {
      if (this.useLink === false) {
        return this.useLink;
      }
      if (
        (this.$route.name === "station" &&
          this.$route.params.groupName &&
          this.$route.params.groupName === this.groupName &&
          this.$route.params.stationName &&
          this.$route.params.stationName === this.stationName) ||
        (this.$route.params.stationId &&
          Number(this.$route.params.stationId) === this.stationId)
      ) {
        return false;
      }
      return this.useLink;
    },
    name() {
      if (this.stationId) {
        return "station-id";
      }
      return "station";
    },
    params() {
      if (typeof this.context !== "undefined" && this.context.trim() !== "") {
        if (this.stationId) {
          return {
            groupName: this.groupName,
            stationName: this.stationName,
            stationId: this.stationId,
            tabName: this.context,
          };
        }
        return {
          groupName: this.groupName,
          stationName: this.stationName,
          tabName: this.context,
        };
      }
      if (this.stationId) {
        return {
          groupName: this.groupName,
          stationName: this.stationName,
          stationId: this.stationId,
          tabName: this.context,
        };
      }
      return {
        groupName: this.groupName,
        stationName: this.stationName,
        tabName: this.context,
      };
    },
  },
};
