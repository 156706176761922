var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"p-0 w-100",on:{"click":_vm.interactWithSpectrogram}},[(_vm.isLoading)?_c('div',{staticClass:"loading-spinner text-center text-primary"},[_c('b-spinner',{attrs:{"label":"Loading Spectrogram..."}})],1):_vm._e(),_vm._v(" "),_c('div',{attrs:{"id":"track-changes-container"}},[_c('button',{attrs:{"id":"save-track-changes"},on:{"click":function () { return _vm.saveTrackChanges(); }}},[_c('font-awesome-icon',{attrs:{"icon":"check"}})],1),_vm._v(" "),_c('button',{attrs:{"id":"cancel-track-changes"},on:{"click":function () { return _vm.cancelTrackChanges(); }}},[_c('font-awesome-icon',{attrs:{"icon":"times"}})],1)]),_vm._v(" "),_c('div',{attrs:{"id":"spectrogram"}}),_vm._v(" "),_c('div',{attrs:{"id":"waveform"}}),_vm._v(" "),_c('div',{staticClass:"flex flex-col",attrs:{"id":"player-bar"}},[_c('div',{staticClass:"player-bar-loader",attrs:{"role":"slider","id":"progress-bar"},on:{"mousedown":_vm.onClickSeek,"touch":_vm.onClickSeek}},[_c('div',{staticClass:"player-bar-loader-progress",attrs:{"id":"loader-progress"}},[_c('div',{staticClass:"player-bar-indicator zoom-indicator",attrs:{"id":"zoom-indicator-start"}}),_vm._v(" "),_c('div',{staticClass:"player-bar-indicator",attrs:{"id":"player-bar-loader-indicator"},on:{"mousedown":_vm.onDragStartTime,"touchstart":_vm.onDragStartTime}}),_vm._v(" "),_c('div',{staticClass:"player-bar-indicator zoom-indicator",attrs:{"id":"zoom-indicator-end"}})])]),_vm._v(" "),_c('div',{staticClass:"player-bar"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('font-awesome-icon',{staticClass:"play-button ml-2 mr-3",attrs:{"icon":[
            'fa',
            _vm.isFinished ? 'redo-alt' : _vm.isPlaying ? 'pause' : 'play' ],"size":"1x"},on:{"click":_vm.togglePlay}}),_vm._v(" "),_c('div',{staticClass:"volume-selection"},[_c('font-awesome-icon',{staticClass:"volume-button",attrs:{"icon":['fa', _vm.volume.muted ? 'volume-mute' : 'volume-up'],"role":"button","size":"lg"},on:{"click":_vm.toggleMute}}),_vm._v(" "),_c('input',{staticClass:"volume-slider",attrs:{"disabled":_vm.volume.muted,"id":"volume-slider","type":"range","min":"0","max":"1","step":"0.01"},on:{"input":_vm.changeVolume}})],1),_vm._v(" "),_c('div',{staticClass:"player-bar-time ml-2 player-time d-flex flex-row"},[_vm._v("\n          "+_vm._s(_vm.time.curr)+" / "+_vm._s(_vm.time.total)+"\n        ")])],1),_vm._v(" "),(_vm.selectedTrack)?_c('div',{staticClass:"selected-track-container"},[_c('b-container',{staticClass:"d-flex selected-class"},[_c('b-row',{staticClass:"justify-content-between"},[_c('span',{staticClass:"track-colour",style:({ background: ("" + (_vm.selectedTrack.colour)) })}),_vm._v(" "),_c('b-col',[_c('b-row',{staticClass:"align-items-center"},[_c('div',[_c('h4',{staticClass:"track-time"},[_vm._v("\n                    Time: "+_vm._s(_vm.selectedTrack.start.toFixed(1))+" -\n                    "+_vm._s(_vm.selectedTrack.end.toFixed(1))+" (Δ"+_vm._s((_vm.selectedTrack.end - _vm.selectedTrack.start).toFixed(1))+"s)\n                  ")]),_vm._v(" "),_c('b-row',{staticClass:"m-0 w-100 justify-content-between capitalize"},[_c('h4',[_vm._v("\n                      "+_vm._s(_vm.selectedTrack.displayTags.length === 0
                          ? "Select Tag..."
                          : _vm.selectedTrack.displayTags.length === 1
                          ? _vm.selectedTrack.displayTags[0].what
                          : "Multiple tags...")+"\n                    ")])])],1)])],1)],1)],1),_vm._v(" "),_c('font-awesome-icon',{staticClass:"ml-2",attrs:{"icon":['fa', 'times'],"size":"1x","role":"button"},on:{"click":function($event){return _vm.setSelectedTrack(null)}}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"player-bar-settings"},[_c('font-awesome-icon',{class:{ highlighted: _vm.zoomed.enabled },attrs:{"icon":['fa', _vm.zoomed.enabled ? 'search-minus' : 'search-plus'],"role":"button","size":"lg"},on:{"click":function($event){_vm.setZoomed(function (zoom) {
              zoom.enabled = !zoom.enabled;
            })}}}),_vm._v(" "),_c('svg',{staticClass:"player-bar-samplerate-button",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","role":"button"},on:{"click":function () {
              _vm.toggleSampleRate = !_vm.toggleSampleRate;
            }}},[_c('path',{class:{ highlighted: _vm.toggleSampleRate },attrs:{"stroke":"white","stroke-width":"1.4","d":"M18.6 10c-1.5 0-1.4 2.9-2 4.2-.2.2-.5.1-.5 0-.8-2.5-.5-8.2-2.6-8.2S12 13.4 11 16.5c0 .2-.3.2-.4 0-1-2.2-.8-6.9-2.5-6.9-1.8 0-1.6 4-2.3 5.6-.1.2-.4.2-.5 0-.5-1-.7-3.2-2.6-3.2H.5a.5.5 0 0 0 0 1h2.2c1.6 0 1 3.5 2.9 3.5 1.6 0 1.5-3.8 2.2-5.5 0-.2.4-.2.5 0 .8 2 .7 7 2.6 7 2 0 1.3-7.9 2.4-10.6.1-.2.4-.2.5 0 1 2.2.5 8 2.4 8 1.6 0 1.7-2.6 2.2-4 0-.2.4-.2.5 0 .5 1 .8 2.6 2.4 2.6h2.2a.5.5 0 0 0 0-1h-2.2c-1.4 0-1.2-3-2.7-3z"}})]),_vm._v(" "),(_vm.toggleSampleRate)?_c('div',{staticClass:"player-bar-samplerate"},[_c('b',[_vm._v("Sample Rate:")]),_vm._v(" "+_vm._s(_vm.newSampleRate)+" Hz\n          "),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newSampleRate),expression:"newSampleRate"}],attrs:{"type":"range","min":"8000","max":"48000","step":"100"},domProps:{"value":(_vm.newSampleRate)},on:{"__r":function($event){_vm.newSampleRate=$event.target.value}}}),_vm._v(" "),_c('font-awesome-icon',{attrs:{"role":"button","icon":['fa', 'check'],"size":"lg"},on:{"click":function () {
                  _vm.setSampleRate(Number(_vm.newSampleRate));
                }}}),_vm._v(" "),_c('font-awesome-icon',{attrs:{"role":"button","icon":['fa', 'times'],"size":"lg"},on:{"click":function () {
                  _vm.newSampleRate = _vm.sampleRate;
                  _vm.toggleSampleRate = false;
                }}})],1),_vm._v(" "),_c('div',{staticClass:"mt-2"},[_c('b',[_vm._v("Toggle Labels:")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.showLabels),expression:"showLabels"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.showLabels)?_vm._i(_vm.showLabels,null)>-1:(_vm.showLabels)},on:{"change":function($event){var $$a=_vm.showLabels,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.showLabels=$$a.concat([$$v]))}else{$$i>-1&&(_vm.showLabels=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.showLabels=$$c}}}})])]):_vm._e(),_vm._v(" "),_c('b-dropdown',{staticClass:"player-bar-color-dropdown",attrs:{"variant":"link","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":['fa', 'palette']}})]},proxy:true}])},[_vm._v(" "),_vm._l((_vm.colours),function(val,index){
                var _obj;
return _c('b-dropdown-item',{key:index,class:( _obj = {
              'dropdown-item': true
            }, _obj['dropdown-highlighted'] = _vm.colour.toLowerCase() === val.toLowerCase(), _obj ),attrs:{"z-index":"1001"},on:{"click":function($event){return _vm.setColour(val)}}},[_vm._v("\n            "+_vm._s(val)+"\n          ")])})],2)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }