



































































































































































import { icon, latLng } from "leaflet";
import api from "@/api";
import * as csv from "csvtojson";
import Help from "@/components/Help.vue";
import MapWithPoints from "@/components/MapWithPoints.vue";
import StationLink from "@/components/StationLink.vue";

// TODO(jon): Do we want to be able to view retired stations?

const Marker = icon({
  iconUrl: "/marker-icon.png",
  iconRetinaUrl: "/marker-icon-2x.png",
  iconSize: [25, 41],
  iconAnchor: [12, 40],
  shadowUrl: "/marker-shadow.png",
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41],
});

interface StationData {
  name: string;
  lat: number;
  lng: number;
}

export default {
  components: {
    StationLink,
    MapWithPoints,
    Help,
  },
  name: "StationsTab",
  props: {
    items: { type: Array, required: true },
    loading: { type: Boolean, default: false },
    isGroupAdmin: { type: Boolean, default: false },
    groupName: { type: String, required: true },
  },
  data() {
    return {
      // Stations state
      icon: Marker,
      backDateRecordings: false,
      applyStationsFromDate: null,
      addingStations: false,
      assignedRecordingsCount: null,
      updateWarnings: null,
      enableEditingStations: false,
      invalidCsvFormat: false,
      invalidCsvReason: "",

      pendingStations: [],
      draggingCsvOver: false,
      stationToRename: null,
      renaming: false,
      newStationName: "",
    };
  },
  computed: {
    stationsTableFields() {
      const fields = [
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          key: "recordingsCount",
          label: "Total Recordings",
          sortable: true,
        },
        {
          key: "latitude",
          label: "Latitude",
        },
        {
          key: "longitude",
          label: "Longitude",
        },
      ];
      if (this.isGroupAdmin) {
        fields.push({
          key: "id",
          label: "Edit",
        });
      }
      return fields;
    },
    stations() {
      return this.items
        .map(({ name, location, id, recordingsCount }) => ({
          id,
          name,
          latitude: location.lat,
          longitude: location.lng,
          rename: "",
          recordingsCount,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    canEditStations() {
      return this.enableEditingStations || !this.groupHasStations;
    },
    groupHasStations() {
      return this.stations.length !== 0;
    },
    stationsForMap() {
      // Stations lat/lng as leaflet lat/lng objects
      return this.stations.map(({ name, latitude, longitude, id }) => ({
        name: name + `_${id}`,
        location: latLng(latitude, longitude),
        id,
      }));
    },
    updateWarningsText() {
      return (
        "<strong>Warnings:</strong><br>" +
        this.updateWarnings.replace(/\n/g, "<br>")
      );
    },
    pendingStationsDiff() {
      // Show pending stations, and mark any existing stations that don't have a match in pending as
      // "will be retired".  Any existing stations with lat/lng changes get marked as "will be updated".
      const diff = {};
      const existingStationsByName = {};
      const pendingStationsByName = {};
      for (const station of this.stations) {
        existingStationsByName[station.name] = station;
      }
      for (const station of this.pendingStations) {
        pendingStationsByName[station.name] = station;
      }
      for (const station of this.stations) {
        if (!pendingStationsByName.hasOwnProperty(station.name)) {
          diff[station.name] = {
            ...station,
            _rowVariant: "retire-item",
            action: "retire",
          };
        } else {
          const updatedStation = pendingStationsByName[station.name];
          const EPSILON = 0.000000000001;
          if (
            Math.abs(updatedStation.latitude - station.latitude) > EPSILON ||
            Math.abs(updatedStation.longitude - station.longitude) > EPSILON
          ) {
            let { latitude, longitude } = updatedStation;
            if (Math.abs(latitude - station.latitude) > EPSILON) {
              latitude = `<del>${Number(station.latitude).toFixed(
                5
              )}</del> -> ${Number(latitude).toFixed(5)}`;
            }
            if (Math.abs(longitude - station.longitude) > EPSILON) {
              longitude = `<del>${Number(station.longitude).toFixed(
                5
              )}</del> -> ${Number(longitude).toFixed(5)}`;
            }
            diff[station.name] = {
              ...updatedStation,
              latitude,
              longitude,
              _rowVariant: "update-item",
              action: "update",
            };
          } else {
            diff[station.name] = {
              ...updatedStation,
              _rowVariant: "no-change-item",
              action: "no change",
            };
          }
        }
      }
      for (const station of this.pendingStations) {
        if (!diff.hasOwnProperty(station.name)) {
          diff[station.name] = {
            ...station,
            _rowVariant: "add-item",
            action: "add",
          };
        }
      }
      return (Object.values(diff) as StationData[]).sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    },
  },
  methods: {
    dragCsvFileOver(event: DragEvent) {
      this.draggingCsvOver = true;
      event.dataTransfer.dropEffect = "none";
    },
    dragCsvFileOut() {
      this.draggingCsvOver = false;
    },
    async droppedStationsCsvFile(event: DragEvent) {
      this.draggingCsvOver = false;
      const csvText = await event.dataTransfer.files[0].text();
      await this.parseStationsCsv(csvText);
    },
    renameStation(station) {
      this.renaming = true;
      this.stationToRename = station.item;
    },
    async removeStation(stationId: number) {
      await api.station.deleteStationById(stationId);
      this.$emit("change");
    },
    async doStationRename() {
      await api.station.renameStationById(
        this.stationToRename.id,
        this.newStationName
      );
      this.$emit("change");
      this.renaming = false;
      this.newStationName = "";
      this.stationToRename = null;
    },
    async gotStationsCsvFile(event: Event) {
      const file: File = (event.target as HTMLInputElement).files[0];
      const csvText = await file.text();
      await this.parseStationsCsv(csvText);
    },
    async parseStationsCsv(csvText: string) {
      let monitoring = await csv().fromString(csvText);
      // Make sure the expected fields exist:
      if (
        !monitoring.every(
          (item) =>
            item.hasOwnProperty("Type") &&
            item.hasOwnProperty("Lat") &&
            item.hasOwnProperty("Lon") &&
            item.hasOwnProperty("Number / Code")
        )
      ) {
        this.invalidCsvFormat = true;
        this.invalidCsvReason =
          "Expected headers for <em>'Number / Code', 'Type', 'Lat', 'Lon'</em>";
        return;
      }

      // Don't allow duplicate station names:
      const names = {};
      for (const item of monitoring) {
        const name = item["Number / Code"].toLowerCase();
        if (names.hasOwnProperty(name)) {
          this.invalidCsvFormat = true;
          this.invalidCsvReason = `Duplicate station name <em>'${name}'</em>`;
          return;
        }
      }

      this.invalidCsvFormat = false;
      monitoring = monitoring.map((item) => ({
        ...item,
        Type: item.Type.toLowerCase(),
      }));
      // If the list has items with the "thermal camera" column, use that, else use "camera"
      const cameraKey = monitoring.find(
        (item) => item.Type === "thermal camera"
      )
        ? "thermal camera"
        : "camera";
      const csvCameras = monitoring
        .filter((item) => item.Type === cameraKey)
        .map((item) => ({
          name: item["Number / Code"],
          latitude: item.Lat,
          longitude: item.Lon,
        }));
      if (!csvCameras.length) {
        this.invalidCsvFormat = true;
        this.invalidCsvReason =
          "Supplied CSV has no rows where the 'type' is either 'Camera' or 'Thermal Camera'";
      }
      this.pendingStations = csvCameras;
    },
    async addNewStations() {
      this.addingStations = true;
      this.assignedRecordingsCount = null;
      this.updateWarnings = null;
      {
        let applyFromDate =
          this.backDateRecordings && this.applyStationsFromDate;
        if (applyFromDate) {
          applyFromDate = new Date(Date.parse(applyFromDate));
          applyFromDate.setHours(5);
          applyFromDate.setMinutes(0);
          applyFromDate.setSeconds(0);
          applyFromDate.setMilliseconds(0);
        }
        const { result } = await api.groups.addStationsToGroup(
          this.groupName,
          this.pendingStations.map(({ name, latitude, longitude }) => ({
            name,
            lat: Number(latitude),
            lng: Number(longitude),
          })),
          applyFromDate
        );
        this.$emit("change");
        this.pendingStations = [];
        if (result.warnings) {
          this.updateWarnings = result.warnings;
        }
        if (Object.values(result.updatedRecordingsPerStation).length !== 0) {
          this.assignedRecordingsCount = Object.values(
            result.updatedRecordingsPerStation
          ).reduce((acc: number, count: number) => acc + count, 0);
        }
        this.enableEditingStations = false;
      }
      this.addingStations = false;
    },
  },
};
