



































































































































































































































































import { PropType } from "vue";
import { defineComponent, onMounted, ref, watch } from "@vue/composition-api";
import Help from "@/components/Help.vue";

import { useState } from "@/utils";

import {
  AudioTrack,
  AudioTracks,
  DisplayTag,
} from "../Video/AudioRecording.vue";
import Dropdown from "../Dropdown.vue";
import ClassificationsDropdown from "../ClassificationsDropdown.vue";

import { TrackId } from "@typedefs/api/common";

import store from "@/stores";
import { shouldViewAsSuperUser } from "@/utils";
import { ApiTrackTag } from "@typedefs/api/trackTag";
enum TrackListFilter {
  All = "all",
  Automatic = "automatic",
  Manual = "manual",
  Unconfirmed = "unconfirmed",
}

export default defineComponent({
  name: "TrackList",
  components: {
    Dropdown,
    ClassificationsDropdown,
    Help,
  },
  props: {
    audioTracks: {
      type: Map as PropType<AudioTracks>,
      required: true,
    },
    selectedTrack: {
      type: Object as PropType<AudioTrack | null>,
    },
    deleteTrack: {
      type: Function as PropType<(track: TrackId) => void>,
      required: true,
    },
    undoDeleteTrack: {
      type: Function as PropType<(track: TrackId) => void>,
      required: true,
    },
    addTagToTrack: {
      type: Function as PropType<
        (trackId: TrackId, what: string) => Promise<AudioTrack>
      >,
      required: true,
    },
    playTrack: {
      type: Function as PropType<(track: AudioTrack) => void>,
      required: true,
    },
    redacted: {
      type: Boolean,
      default: false,
    },
    filteredTags: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    onAddFilterTags: {
      type: Function as PropType<(tags: string[]) => void>,
      default: () => {},
    },
    isGroupAdmin: {
      type: Boolean,
      default: false,
    },
    setFilteredNoise: {
      type: Function as PropType<(show: boolean) => void>,
      default: () => {},
    },
  },
  computed: {
    isSuperUserAndViewingAsSuperUser(): boolean {
      return (
        this.$store.state.User.userData.isSuperUser && shouldViewAsSuperUser()
      );
    },
  },
  methods: {
    tags(track) {
      let items = track.tags;
      if (!this.isSuperUserAndViewingAsSuperUser) {
        // Remove AI tags other than master, as they'll just be confusing
        items = items.filter(
          (item: ApiTrackTag) => !item.automatic || item.data.name === "Master"
        );
      }
      return items;
    },
    aiName: function (trackTag: ApiTrackTag) {
      if (
        this.isSuperUserAndViewingAsSuperUser &&
        trackTag.automatic &&
        trackTag.data.name
      ) {
        return "AI " + trackTag.data.name;
      } else {
        return "Cacophony AI";
      }
    },
  },
  setup(props) {
    const confirmTrack = async (track: AudioTrack, tag: DisplayTag) => {
      if (!tag) {
        return;
      }
      await props.addTagToTrack(track.id, tag.what);
    };
    const toggledTrackHistory = ref<TrackId[]>([]);
    const showFilteredNoise = ref(false);
    watch(showFilteredNoise, (val) => {
      props.setFilteredNoise(val);
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const filter = ref(TrackListFilter.All);
    const filterTracks = (track: AudioTrack) => {
      switch (filter.value) {
        case TrackListFilter.All:
          return true;
        case TrackListFilter.Automatic:
          return track.displayTags.some((tag) => tag.class === "automatic");
        case TrackListFilter.Manual:
          return track.displayTags.some((tag) => tag.class === "human");
        default:
          return false;
      }
    };

    const setFilter = (newFilter: TrackListFilter) => {
      filter.value = newFilter;
    };

    const sortTracks = (trackA: AudioTrack, trackB: AudioTrack) => {
      return trackA.start - trackB.start;
    };
    const [tracks, setTracks] = useState<AudioTrack[]>(
      [...props.audioTracks.values()].filter(filterTracks).sort(sortTracks)
    );
    watch(
      () => props.audioTracks,
      (newTracks) => {
        setTracks(
          [...newTracks.values()].filter(filterTracks).sort(sortTracks)
        );
      }
    );
    onMounted(() => {
      watch(
        tracks,
        (newTracks) => {
          // temp style fix for sizing #classification-id
          const list = document.getElementById("classification-list");
          if (list) {
            const pxPerItem = 110;
            const cssHeight = pxPerItem * newTracks.length;
            list.style.height = `${cssHeight}px`;
          }
        },
        { immediate: true }
      );
    });

    watch(
      () => props.selectedTrack,
      (track) => {
        if (track) {
          const list = document.getElementById("classification-list");
          const element = document.getElementById(`tag-item-${track.id}`);
          if (list && element) {
            const scrollPosition = element.offsetTop - list.offsetTop;

            // Step 4: Scroll
            list.scrollTop = scrollPosition;
          }
        }
      }
    );

    watch(
      () => [props.audioTracks, props.selectedTrack] as const,
      (curr, prev) => {
        const [tracks, selectedTrack] = curr;
        const [prevTracks, prevSelectedTrack] = prev;
        if (
          selectedTrack?.id === prevSelectedTrack?.id ||
          tracks === prevTracks
        ) {
          return;
        }
        const list = document.getElementById("classification-list");
        if (list) {
          list.scrollTop = 0;
        }
      }
    );

    return {
      userName: store.state.User.userData.userName,
      tracks,
      setFilter,
      toggledTrackHistory,
      confirmTrack,
      showFilteredNoise,
      filter,
      filterTracks,
      sortTracks,
      TrackListFilter,
    };
  },
});
