var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('div',{staticClass:"container",staticStyle:{"padding":"0"}},[_c('h2',[_vm._v("\n    Devices\n    "),_c('help',[_vm._v("\n      Devices specify which group they belong to when they first register.\n      They can't be edited here.\n    ")])],1),_vm._v(" "),_c('div',[(_vm.groupHasDevices)?_c('p',[_vm._v("\n      Devices associated with\n      "),_c('strong',[_c('GroupLink',{attrs:{"group-name":_vm.groupName}})],1),_vm._v(":\n    ")]):_c('p',{staticClass:"description-and-button-wrapper"},[_vm._v("\n      There are currently no devices associated with "),_c('strong',[_c('GroupLink',{attrs:{"group-name":_vm.groupName}})],1),_vm._v(".\n    ")])]),_vm._v(" "),_c('MapWithPoints',{attrs:{"points":_vm.devicesForMap,"radius":60,"navigate-to-point":function (point) { return ({
        name: 'device',
        params: {
          groupName: _vm.groupName,
          deviceName: point.name,
          tabName: 'recordings',
        },
      }); }}}),_vm._v(" "),(_vm.groupHasDevices)?_c('b-table',{attrs:{"items":_vm.tableItems,"fields":[
      {
        key: 'deviceName',
        label: 'Device Name',
        sortable: true,
      },
      {
        key: 'deviceHealth',
        label: 'Device Health',
        sortable: true,
        formatter: _vm.sortBool,
        sortByFormatted: true,
      } ],"sort-by":"deviceName","hover":"","outlined":"","responsive":"","data-cy":"devices-table"},scopedSlots:_vm._u([{key:"cell(deviceName)",fn:function(row){return [_c('DeviceLink',{attrs:{"group-name":_vm.groupName,"device-name":row.item.deviceName,"type":row.item.type,"context":"recordings"}})]}},{key:"cell(deviceHealth)",fn:function(row){return [(row.item.type === 'thermal')?_c('span',{class:[{ healthy: row.item.isHealthy }, 'device-health']},[(row.item.isHealthy)?_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":"heart"}}):_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":"heart-broken"}})],1):_vm._e(),_vm._v(" "),(!row.item.type)?_c('b-spinner',{attrs:{"type":"border","small":""}}):(row.item.type !== 'thermal')?_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":"question"}}):_vm._e()]}}],null,false,3924441328)}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }