import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faAsterisk,
  faBackward,
  faBatteryEmpty,
  faBatteryFull,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryThreeQuarters,
  faBolt,
  faCaretDown,
  faCaretUp,
  faChartBar,
  faCheckCircle,
  faCog,
  faDownload,
  faEllipsisV,
  faEyeDropper,
  faExclamationTriangle,
  faFileDownload,
  faFileExport,
  faForward,
  faGlasses,
  faHeart,
  faHeartbeat,
  faHeartBroken,
  faImage,
  faInfoCircle,
  faReply,
  faMicrochip,
  faMapMarkerAlt,
  faMusic,
  faPlay,
  faStop,
  faPause,
  faPalette,
  faPowerOff,
  faQuestion,
  faQuestionCircle,
  faRedoAlt,
  faSearch,
  faSearchPlus,
  faSearchMinus,
  faStar,
  faStream,
  faStepForward,
  faStepBackward,
  faSpinner,
  faTimes,
  faTimesCircle,
  faTrash,
  faThumbsUp,
  faThumbsDown,
  faUsers,
  faVideo,
  faWrench,
  faUser,
  faUserCog,
  faUserSecret,
  faPlus,
  faVolumeUp,
  faVolumeMute,
  faUserPlus,
  faChevronRight,
  faUndo,
  faThumbtack,
  faCheck,
  faImages,
  faPencilAlt,
  faShieldAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCalendar,
  faCaretSquareDown,
  faCaretSquareLeft,
  faCaretSquareRight,
  faCaretSquareUp,
  faClock,
  faFile,
  faFileAudio,
  faFileVideo,
  faUserCircle,
  faWindowClose,
} from "@fortawesome/free-regular-svg-icons";

// https://www.npmjs.com/package/@fortawesome/vue-fontawesome
library.add(
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faAsterisk,
  faBackward,
  faBatteryEmpty,
  faBatteryFull,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryThreeQuarters,
  faBolt,
  faCalendar,
  faCaretDown,
  faCaretSquareDown,
  faCaretSquareLeft,
  faCaretSquareRight,
  faCaretSquareUp,
  faCaretUp,
  faChartBar,
  faCheckCircle,
  faClock,
  faCog,
  faDownload,
  faEllipsisV,
  faEyeDropper,
  faExclamationTriangle,
  faFile,
  faFileAudio,
  faFileExport,
  faFileVideo,
  faFileDownload,
  faForward,
  faGlasses,
  faImage,
  faInfoCircle,
  faHeart,
  faHeartbeat,
  faHeartBroken,
  faMicrochip,
  faMusic,
  faPlay,
  faStop,
  faPalette,
  faPause,
  faPowerOff,
  faQuestion,
  faQuestionCircle,
  faReply,
  faShieldAlt,
  faRedoAlt,
  faSearch,
  faSearchPlus,
  faSearchMinus,
  faStar,
  faStepForward,
  faStepBackward,
  faStream,
  faSpinner,
  faTimes,
  faTimesCircle,
  faThumbsUp,
  faThumbsDown,
  faTrash,
  faUserCircle,
  faUsers,
  faVideo,
  faWindowClose,
  faWrench,
  faUser,
  faUserCog,
  faPlus,
  faUserPlus,
  faUserSecret,
  faChevronRight,
  faVolumeUp,
  faVolumeMute,
  faMapMarkerAlt,
  faUndo,
  faThumbtack,
  faCheck,
  faImages,
  faPencilAlt
);

export default FontAwesomeIcon;
